<template>
    <el-row>
      <el-col class="fold" :span="23" :offset="1">
           <span>{{ $t("Select Icons") }}</span>
          <el-divider/>
      </el-col>
    </el-row>
    <el-row>
        <el-col class="left" :span="23" :offset="1">
          <span class="icon-label">There is not any customization service for this device</span>
      </el-col>
    </el-row>
</template>
  
  <script setup>
    import { ref, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { MATERIAL_STEP, CONFIGURATION_STEP } from "@/store/constants"
    // @ is an alias to /src

    const store = useStore();

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/configuration',
        previousRoute: '/touchPanel/material',
        nextStep: CONFIGURATION_STEP,
        previousStep: MATERIAL_STEP
      });
    })
  </script>

  <style scoped>
  .el-button {
    position: relative;
    border: none;
    height: 120px;
    width: 120px;
    margin-bottom: 5px;
  }
 .icon-label {
    margin-left: 10px;
 }
 .frame {
    display: inline;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  .left {
    text-align: left;
  }
  .fold {
    text-align: left;
    font-size: 24px;
    margin-top: 20px;
  }
  .el-divider{
  margin-top: 10px;
 }
  </style>
  