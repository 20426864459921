<template>
    <el-row>
      <el-col class="fold" :span="23" :offset="1">
           <span>Material Select</span>
          <el-divider/>
      </el-col>
    </el-row>
    <el-row>
        <el-col class="left" :span="23" :offset="1">
          <span class="icon-label">There is no material option for this device</span>
      </el-col>
    </el-row>
</template>
  
  <script setup>
    import Materials from '../Materials.vue'
    import { COLOR, MODEL_STEP, CONFIGURATION_STEP } from "@/store/constants"

    import {onMounted} from 'vue'
    import { useStore } from 'vuex'

    const store = useStore();

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/configuration',
        previousRoute: '/climateGateway/model',
        nextStep: CONFIGURATION_STEP,
        previousStep: MODEL_STEP
      });
    })
  </script>

<style scoped>
.el-button {
  position: relative;
  border: none;
  height: 120px;
  width: 120px;
  margin-bottom: 5px;
}
.icon-label {
  margin-left: 10px;
}
.frame {
  display: inline;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}
.left {
  text-align: left;
}
.fold {
  text-align: left;
  font-size: 24px;
  margin-top: 20px;
}
.el-divider{
margin-top: 10px;
}
</style>
  