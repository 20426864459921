<template>
    <el-row justify="center">
      <el-col class="fold-text" :span="23" :offset="1">
           <span>{{ $t("Select Icons") }}</span>
          <el-divider/>
      </el-col>
      <el-col :xs="{span: 24, offset: 0}" :span="23" :offset="1">
        <div class="image-box">
            <el-image :src=IconFoundation fit="contain"/>
            <div v-if="store.getters.getFold == 'Fold-4'">
              <IconPicker :index = 0 :location = 0 :top = '13' :left = '13' />
              <IconPicker :index = 1 :location = 1 :top = '13' :left = '87'/>
              <IconPicker :index = 2 :location = 2 :top = '37' :left = '13'/>
              <IconPicker :index = 3 :location = 3 :top = '37' :left = '87'/>
              <IconPicker :index = 4 :location = 4 :top = '63' :left = '13'/>
              <IconPicker :index = 5 :location = 5 :top = '63' :left = '87'/>
              <IconPicker :index = 6 :location = 6 :top = '87' :left = '13'/>
              <IconPicker :index = 7 :location = 7 :top = '87' :left = '87'/>
            </div>
            <div v-if="store.getters.getFold == 'Fold-2'">
              <IconPicker :index = 0 :location = 0 :top = '13' :left = '13' />
              <IconPicker :index = 1 :location = 1 :top = '13' :left = '87'/>
              <IconPicker :index = 0 :location = 2 :top = '37' :left = '13'/>
              <IconPicker :index = 1 :location = 3 :top = '37' :left = '87'/>
              <IconPicker :index = 2 :location = 4 :top = '63' :left = '13'/>
              <IconPicker :index = 3 :location = 5 :top = '63' :left = '87'/>
              <IconPicker :index = 2 :location = 6 :top = '87' :left = '13'/>
              <IconPicker :index = 3 :location = 7 :top = '87' :left = '87'/>
            </div>
            <div v-if="store.getters.getFold == 'Fold-1'">
              <IconPicker :index = 0 :location = 0 :top = '13' :left = '13'/>
              <IconPicker :index = 1 :location = 1 :top = '13' :left = '87'/>
              <IconPicker :index = 0 :location = 2 :top = '37' :left = '13'/>
              <IconPicker :index = 1 :location = 3 :top = '37' :left = '87'/>
              <IconPicker :index = 0 :location = 4 :top = '63' :left = '13'/>
              <IconPicker :index = 1 :location = 5 :top = '63' :left = '87'/>
              <IconPicker :index = 0 :location = 6 :top = '87' :left = '13'/>
              <IconPicker :index = 1 :location = 7 :top = '87' :left = '87'/>
            </div>
          </div>
        </el-col>
    </el-row>
</template>
  
  <script setup>
    import { ref, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { MATERIAL_STEP, CONFIGURATION_STEP } from "@/store/constants"
    // @ is an alias to /src
    import IconPicker from "@/components/IconPicker.vue"
    import IconFoundation from "@/assets/img/pushButtonSwitch/icon/icon-main.png"

    const store = useStore();

    const fold = store.getters.getFold;

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/configuration',
        previousRoute: '/pushButtonSwitch/material',
        nextStep: CONFIGURATION_STEP,
        previousStep: MATERIAL_STEP
      });
      setDefaultIcon(fold);
    })

    const setDefaultIcon = (fold) => { 
      if(fold == 'Fold-1'){
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 0, location: 6})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 1, location: 7})
      }
      if(fold == 'Fold-2'){
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 0, location: 0})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 1, location: 1})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 2, location: 6})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 3, location: 7})
      }
      if(fold == 'Fold-4'){
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 0, location: 0})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 1, location: 1})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 2, location: 2})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 3, location: 3})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 4, location: 4})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 5, location: 5})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 6, location: 6})
        store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 7, location: 7})
      }

    }
  </script>

  <style scoped>
 .fold-text {
    text-align: left;
    font-size: 1.8vw;
    margin-top: 1.6vw;
  }
  .image-box{
    position: relative;

  }
  .el-divider{
  margin-top: 10px;
 }
 /* extra large screens */
 @media only screen and (min-width: 1941px) {
  .image-box,
  .el-image {
    max-width: 650px;
  }
}
/* large screen */
@media only screen and (max-width: 1940px) {
  .image-box,
  .el-image {
    max-width: 400px;
  }
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .image-box,
  .el-image {
    max-width: 250px;
  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .image-box,
  .el-image {
    max-width: 250px;
    margin: auto;
  }
  .fold-text {
    font-size: 4vw;
    margin-top: 7vw;
  }
}
  </style>
  