export const VERSION = '1.0.3'
export const EXPIRE_TIME = 1800000; //60 min

export const ELECTRONIC_PART = 'electronicPart'
export const FOLD = 'fold'; 
export const COLOR = 'color';
export const KNOB = 'knob';

//Steps
export const PRODUCT_STEP = 0;
export const MODEL_STEP = 1;
export const MATERIAL_STEP = 2;
export const CUSTOMIZATION_STEP = 3;
export const CONFIGURATION_STEP = 4;
export const COMPLETE_STEP = 5;




