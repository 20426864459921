<template>
    <el-row>
      <el-col class="fold-text" :span="23" :offset="1">
          <span>{{ $t("Select Icons") }}</span>
          <el-divider/>
      </el-col>
      <el-col :xs="{span: 24, offset: 0}" :span="23" :offset="1">
        <div class="image-box">
          <el-image :src=IconFoundation fit="contain"/>
          <IconPicker :index = 0 :location = 0 :top = '87' :left = '13'/>
          <IconPicker :index = 1 :location = 1 :top = '87' :left = '87'/>
        </div>
      </el-col>
    </el-row>
    <!-- <el-row>
        <el-col class="left container" :span="23" :offset="1">
            <el-image :src=IconFoundation fit="contain"/>
            <IconPicker :index = 0 :top = '82' :left = '10'/>
            <IconPicker :index = 1 :top = '82' :left = '72'/>
        </el-col>
    </el-row> -->
</template>
  
  <script setup>
    import { ref, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { MATERIAL_STEP, CONFIGURATION_STEP } from "@/store/constants"
    // @ is an alias to /src
    import IconPicker from "@/components/IconPicker.vue"
    import IconFoundation from "@/assets/img/thermostat/icon/icon-main.png"

    const store = useStore();

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/configuration',
        previousRoute: '/thermostat/materialKnob',
        nextStep: CONFIGURATION_STEP,
        previousStep: MATERIAL_STEP
      });
      setDefaultIcon();
    })

    const setDefaultIcon = () => { 
      store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 0, location: 0})
      store.commit("setIconPack", {icon: "M7", url: "/img/M7.d09b8b56.svg", index: 1, location: 1})
    }


  </script>

  <style scoped>
 .fold-text {
    text-align: left;
    font-size: 1.8vw;
    margin-top: 1.6vw;
  }
  .image-box{
    position: relative;

  }
  .el-divider{
  margin-top: 10px;
 }

 /* extra large screens */
 @media only screen and (min-width: 1941px) {
  .image-box,
  .el-image {
    max-width: 650px;
  }
}
/* large screen */
@media only screen and (max-width: 1940px) {
  .image-box,
  .el-image {
    max-width: 400px;
  }
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .image-box,
  .el-image {
    max-width: 250px;
  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .image-box,
  .el-image {
    max-width: 250px;
    margin: auto;
  }
  .fold-text {
    font-size: 4vw;
    margin-top: 7vw;
  }
}
  </style>
  