import { createApp } from 'vue'

import router from './router'
import store from './store/store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './assets/sass/style.css'
import 'vue3-carousel/dist/carousel.css';
import FlagIcon from 'vue-flag-icon'

import App from './App.vue'
import i18n from './i18n'

const app = createApp(App)
            .use(i18n)
            .use(store)
            .use(router)
            .use(FlagIcon)
            .use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
