<template>
    <div class="image-box" :class="{ preTable: isActiveTableView, prePdf: isActivePdfView }">
      <Transition>
        <el-image :key="colors[color]" :src=colors[color] class="fold-image" fit="contain" style="position: absolute;"/>
      </Transition>
      <el-image :src=PushButtonSwitch fit="contain" style="z-index: -99;"/>
      <el-image :src=AluminumBlack class="fold-image" fit="contain" style="z-index: -1;"/>
      <el-image v-if="fold != 'Fold-1' && color != ''" class="fold-image" :key="folds[fold]" :src=folds[fold] fit="contain" style="position: absolute;"/>

      <!-- for animation caching -->
      <div v-if="!isTableView">
        <div v-show="false" v-for="(color,index) in colors" :key="index">
          <el-image :src=color />
        </div>
      </div>

        <!-- icons -->
        <div v-if="fold == 'Fold-1'">
          <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 0" :style="{color: iconColorCode}" style="left: 13%; top: 13%;"><Icon :name="iconPack[0].icon"/></el-icon>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 0 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 13%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 0 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 13%;">{{iconPack[0].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 1" :style="{color: iconColorCode}" style="left: 87%; top: 13%;"><Icon :name="iconPack[1].icon"/></el-icon>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 1 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 13%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 1 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 13%;">{{iconPack[1].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 2" :style="{color: iconColorCode}" style="left: 13%; top: 37%;"><Icon :name="iconPack[0].icon"/></el-icon>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 2 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 37%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 2 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 37%;">{{iconPack[0].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 3" :style="{color: iconColorCode}" style="left: 87%; top: 37%;"><Icon :name="iconPack[1].icon"/></el-icon>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 3 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 37%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 3 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 37%;">{{iconPack[1].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 4" :style="{color: iconColorCode}" style="left: 13%; top: 62%;"><Icon :name="iconPack[0].icon"/></el-icon>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 4 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 62%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 4 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 62%;">{{iconPack[0].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 5" :style="{color: iconColorCode}" style="left: 87%; top: 62%;"><Icon :name="iconPack[1].icon"/></el-icon>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 5 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 62%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 5 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 62%;">{{iconPack[1].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 6" :style="{color: iconColorCode}" style="left: 13%; top: 87%;"><Icon :name="iconPack[0].icon"/></el-icon>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 7" :style="{color: iconColorCode}" style="left: 87%; top: 87%;"><Icon :name="iconPack[1].icon"/></el-icon>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
          
          <!-- <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 87%;">
            <Icon :name="iconPack[0].icon"/>
          </el-icon>
          <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 85%;">
            <Icon :name="iconPack[0].icon"/>
          </el-icon>
          <div v-if="iconSize != 'small'">
            <span v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>
            <span v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>
          </div>
          <div v-if="iconSize == 'small'">
            <span v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 85%;">{{iconPack[0].icon}}</span>
            <span v-if="iconPack[0] != undefined && iconPack[0].location == 6 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 85%;">{{iconPack[0].icon}}</span>           
          </div>


          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 87%;">
            <Icon :name="iconPack[1].icon"/>
          </el-icon>
          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 85%;">
            <Icon :name="iconPack[1].icon"/>
          </el-icon>
          <div v-if="iconSize != 'small'">
            <span v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
            <span v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
          </div>
          <div v-if="iconSize == 'small'">
            <span v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 85%;">{{iconPack[1].icon}}</span>
            <span v-if="iconPack[1] != undefined && iconPack[1].location == 7 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 85%;">{{iconPack[1].icon}}</span>            
          </div> -->

        </div>


        <div v-if="fold == 'Fold-2'">
          <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 0" :style="{color: iconColorCode}" style="left: 13%; top: 13%;"><Icon :name="iconPack[0].icon"/></el-icon>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 0 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 13%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 0 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 13%;">{{iconPack[0].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 1" :style="{color: iconColorCode}" style="left: 87%; top: 13%;"><Icon :name="iconPack[1].icon"/></el-icon>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 1 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 13%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 1 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 13%;">{{iconPack[1].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconPack[0].location == 2" :style="{color: iconColorCode}" style="left: 13%; top: 37%;"><Icon :name="iconPack[0].icon"/></el-icon>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 2 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 37%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 2 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 37%;">{{iconPack[0].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 3" :style="{color: iconColorCode}" style="left: 87%; top: 37%;"><Icon :name="iconPack[1].icon"/></el-icon>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 3 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 37%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 3 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 37%;">{{iconPack[1].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[2] != undefined && iconPack[2].location == 4" :style="{color: iconColorCode}" style="left: 13%; top: 62%;"><Icon :name="iconPack[2].icon"/></el-icon>
          <span v-if="iconPack[2] != undefined && iconPack[2].location == 4 && iconPack[2].url == 'TXT' && !isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 62%;">{{iconPack[2].icon}}</span>
          <span v-if="iconPack[2] != undefined && iconPack[2].location == 4 && iconPack[2].url == 'TXT' && isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 62%;">{{iconPack[2].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[3] != undefined && iconPack[3].location == 5" :style="{color: iconColorCode}" style="left: 87%; top: 62%;"><Icon :name="iconPack[3].icon"/></el-icon>
          <span v-if="iconPack[3] != undefined && iconPack[3].location == 5 && iconPack[3].url == 'TXT' && !isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 62%;">{{iconPack[3].icon}}</span>
          <span v-if="iconPack[3] != undefined && iconPack[3].location == 5 && iconPack[3].url == 'TXT' && isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 62%;">{{iconPack[3].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[2] != undefined && iconPack[2].location == 6" :style="{color: iconColorCode}" style="left: 13%; top: 87%;"><Icon :name="iconPack[2].icon"/></el-icon>
          <span v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconPack[2].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[2].icon}}</span>
          <span v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconPack[2].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[2].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[3] != undefined && iconPack[3].location == 7" :style="{color: iconColorCode}" style="left: 87%; top: 87%;"><Icon :name="iconPack[3].icon"/></el-icon>
          <span v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconPack[3].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 87%;">{{iconPack[3].icon}}</span>
          <span v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconPack[3].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[3].icon}}</span>

          <!-- <el-icon :class=iconImage v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 87%;">
            <Icon :name="iconPack[2].icon"/>
          </el-icon>
          <el-icon :class=iconImage v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 85%;">
            <Icon :name="iconPack[2].icon"/>
          </el-icon>
          <div v-if="iconSize != 'small'">
            <span v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconPack[2].url == 'TXT' && !isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[2].icon}}</span>
            <span v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconPack[2].url == 'TXT' && isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[2].icon}}</span>
          </div>
          <div v-if="iconSize == 'small'">
            <span v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconPack[2].url == 'TXT' && !isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 85%;">{{iconPack[2].icon}}</span>
            <span v-if="iconPack[2] != undefined && iconPack[2].location == 6 && iconPack[2].url == 'TXT' && isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 85%;">{{iconPack[2].icon}}</span>           
          </div>


          <el-icon :class=iconImage v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 87%;">
            <Icon :name="iconPack[3].icon"/>
          </el-icon>
          <el-icon :class=iconImage v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 85%;">
            <Icon :name="iconPack[3].icon"/>
          </el-icon>
          <div v-if="iconSize != 'small'">
            <span v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconPack[3].url == 'TXT' && !isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 87%;">{{iconPack[3].icon}}</span>
            <span v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconPack[3].url == 'TXT' && isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[3].icon}}</span>
          </div>
          <div v-if="iconSize == 'small'">
            <span v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconPack[3].url == 'TXT' && !isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 85%;">{{iconPack[3].icon}}</span>
            <span v-if="iconPack[3] != undefined && iconPack[3].location == 7 && iconPack[3].url == 'TXT' && isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 85%;">{{iconPack[3].icon}}</span>            
          </div> -->
        </div>


        <div v-if="fold == 'Fold-4'">
          <el-icon :class=[iconImage] v-if="iconPack[0] != undefined && iconPack[0].location == 0" :style="{color: iconColorCode}" style="left: 13%; top: 13%;"><Icon :name="iconPack[0].icon"/></el-icon>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 0 && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 13%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].location == 0 && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 13%;">{{iconPack[0].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconPack[1].location == 1" :style="{color: iconColorCode}" style="left: 87%; top: 13%;"><Icon :name="iconPack[1].icon"/></el-icon>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 1 && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 13%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].location == 1 && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 13%;">{{iconPack[1].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[2] != undefined && iconPack[2].location == 2" :style="{color: iconColorCode}" style="left: 13%; top: 37%;"><Icon :name="iconPack[2].icon"/></el-icon>
          <span v-if="iconPack[2] != undefined && iconPack[2].location == 2 && iconPack[2].url == 'TXT' && !isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 37%;">{{iconPack[2].icon}}</span>
          <span v-if="iconPack[2] != undefined && iconPack[2].location == 2 && iconPack[2].url == 'TXT' && isRTL(iconPack[2].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 37%;">{{iconPack[2].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[3] != undefined && iconPack[3].location == 3" :style="{color: iconColorCode}" style="left: 87%; top: 37%;"><Icon :name="iconPack[3].icon"/></el-icon>
          <span v-if="iconPack[3] != undefined && iconPack[3].location == 3 && iconPack[3].url == 'TXT' && !isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 37%;">{{iconPack[3].icon}}</span>
          <span v-if="iconPack[3] != undefined && iconPack[3].location == 3 && iconPack[3].url == 'TXT' && isRTL(iconPack[3].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 37%;">{{iconPack[3].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[4] != undefined && iconPack[4].location == 4" :style="{color: iconColorCode}" style="left: 13%; top:62%;"><Icon :name="iconPack[4].icon"/></el-icon>
          <span v-if="iconPack[4] != undefined && iconPack[4].location == 4 && iconPack[4].url == 'TXT' && !isRTL(iconPack[4].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top:62%;">{{iconPack[4].icon}}</span>
          <span v-if="iconPack[4] != undefined && iconPack[4].location == 4 && iconPack[4].url == 'TXT' && isRTL(iconPack[4].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top:62%;">{{iconPack[4].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[5] != undefined && iconPack[5].location == 5" :style="{color: iconColorCode}" style="left: 87%; top: 62%;"><Icon :name="iconPack[5].icon"/></el-icon>
          <span v-if="iconPack[5] != undefined && iconPack[5].location == 5 && iconPack[5].url == 'TXT' && !isRTL(iconPack[5].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 62%;">{{iconPack[5].icon}}</span>
          <span v-if="iconPack[5] != undefined && iconPack[5].location == 5 && iconPack[5].url == 'TXT' && isRTL(iconPack[5].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 62%;">{{iconPack[5].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[6] != undefined && iconPack[6].location == 6" :style="{color: iconColorCode}" style="left: 13%; top: 87%;"><Icon :name="iconPack[6].icon"/></el-icon>
          <span v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconPack[6].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[6].icon}}</span>
          <span v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconPack[6].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[6].icon}}</span>

          <el-icon :class=iconImage v-if="iconPack[7] != undefined && iconPack[7].location == 7" :style="{color: iconColorCode}" style="left: 87%; top: 87%;"><Icon :name="iconPack[7].icon"/></el-icon>
          <span v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconPack[7].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 87%;">{{iconPack[7].icon}}</span>
          <span v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconPack[7].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[7].icon}}</span>

          <!-- <el-icon :class=iconImage v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 87%;">
            <Icon :name="iconPack[6].icon"/>
          </el-icon>
          <el-icon :class=iconImage v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 85%;">
            <Icon :name="iconPack[6].icon"/>
          </el-icon>
          <div v-if="iconSize != 'small'">
            <span v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconPack[6].url == 'TXT' && !isRTL(iconPack[6].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[6].icon}}</span>
            <span v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconPack[6].url == 'TXT' && isRTL(iconPack[6].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[6].icon}}</span>
          </div>
          <div v-if="iconSize == 'small'">
            <span v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconPack[6].url == 'TXT' && !isRTL(iconPack[6].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 85%;">{{iconPack[6].icon}}</span>
            <span v-if="iconPack[6] != undefined && iconPack[6].location == 6 && iconPack[6].url == 'TXT' && isRTL(iconPack[6].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 85%;">{{iconPack[6].icon}}</span>           
          </div>


          <el-icon :class=iconImage v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 87%;">
            <Icon :name="iconPack[7].icon"/>
          </el-icon>
          <el-icon :class=iconImage v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 85%;">
            <Icon :name="iconPack[7].icon"/>
          </el-icon>
          <div v-if="iconSize != 'small'">
            <span v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconPack[7].url == 'TXT' && !isRTL(iconPack[7].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 87%;">{{iconPack[7].icon}}</span>
            <span v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconPack[7].url == 'TXT' && isRTL(iconPack[7].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[7].icon}}</span>
          </div>
          <div v-if="iconSize == 'small'">
            <span v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconPack[7].url == 'TXT' && !isRTL(iconPack[7].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 85%;">{{iconPack[7].icon}}</span>
            <span v-if="iconPack[7] != undefined && iconPack[7].location == 7 && iconPack[7].url == 'TXT' && isRTL(iconPack[7].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 85%;">{{iconPack[7].icon}}</span>            
          </div> -->

        </div>
    </div>
</template>
  
  <script setup>
    import { ref, computed, defineProps } from 'vue'
    import { useStore } from 'vuex'
    // @ is an alias to /src
    import PushButtonSwitch from "@/assets/img/pushButtonSwitch/push-button-switch.png"
    import Icon from "@/components/Icon.vue"

    import Fold2 from "@/assets/img/pushButtonSwitch/folds/fold-2.png"
    import Fold4 from "@/assets/img/pushButtonSwitch/folds/fold-4.png"

    import AluminumBlack from "@/assets/img/pushButtonSwitch/colors/aluminum-black.png"
    import AluminumGrey from "@/assets/img/pushButtonSwitch/colors/aluminum-grey.png"
    import Brass from "@/assets/img/pushButtonSwitch/colors/brass.png"
    import CocoaGrey from "@/assets/img/pushButtonSwitch/colors/cocoa-grey.png"
    import GlacierWhite from "@/assets/img/pushButtonSwitch/colors/glacier-white.png"
    import ObsidianBlack from "@/assets/img/pushButtonSwitch/colors/obsidian-black.png"
    import SandBeige from "@/assets/img/pushButtonSwitch/colors/sand-beige.png"
    import StainlessSteel from "@/assets/img/pushButtonSwitch/colors/stainless-steel.png"
    import TitaniumGrey from "@/assets/img/pushButtonSwitch/colors/titanium-grey.png"
    import Verdant from "@/assets/img/pushButtonSwitch/colors/verdant.png"

    const props = defineProps({
      fold: String,
      color: String,
      iconPack: Array,
      iconSize: String,
      iconColorCode: String,
      isTableView: Boolean,
      isPdfView: Boolean
    });

    const store = useStore();

    const lightColors = [
      "Aluminum Grey","Stainless Steel", "Glacier White", "Sand Beige"
    ]
    
    const iconImage = computed(() => {
        return props.iconSize == 'small' ? 'icon-image-s' : 'icon-image-l'
    })
    const textIcon = computed(() => {
        return props.iconSize == 'small' ? 'text-s' : 'text-l'
    })
    const textIconArabic = computed(() => {
        return props.iconSize == 'small' ? 'text-s-arabic' : 'text-l-arabic'
    })
    const isActiveTableView = computed(() => {
        return props.isTableView
    })
    const isActivePdfView = computed(() => {
        return props.isPdfView
    })

    const colors = {
        '': PushButtonSwitch,
        "Aluminum Black": AluminumBlack,
        "Aluminum Grey": AluminumGrey,
        "Brass": Brass,
        "Cocoa Grey": CocoaGrey,
        "Glacier White": GlacierWhite,
        "Obsidian Black": ObsidianBlack,
        "Sand Beige": SandBeige,
        "Stainless Steel": StainlessSteel,
        "Titanium Grey": TitaniumGrey,
        "Verdant": Verdant,
    };

    const folds = {
        "Fold-1": '',
        "Fold-2": Fold2,
        "Fold-4": Fold4,
    };

    const isRTL =(s) =>{           
        var ltrChars    = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF'+'\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF',
            rtlChars    = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC',
            rtlDirCheck = new RegExp('^[^'+ltrChars+']*['+rtlChars+']');

        return rtlDirCheck.test(s);
    };
  </script>

  <style scoped>
 .image-box{
    position: relative;
    margin: auto;
  }
  .preTable{
    height: 131px;
  }
  .prePdf{
    height: 151px;
  }
 .fold-image {
    display: inline;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
 }
 .icon-image-l{
    position: absolute;
    font-size: 40px;
    transform: translate(-50%, -50%);
 }
 .icon-image-s{
    position: absolute;
    font-size: 12.5px;
    transform: translate(-50%, -50%);
 }
 .text-l{
    position: absolute;
    font-size: 20px;
    font-family: "CORELASERV2", sans-serif;
    color: #fff;
    transform: translate(-50%, -50%);
    white-space: nowrap;
 }
 .text-s{
    position: absolute;
    font-size: 5px;
    font-family: "CORELASERV2", sans-serif;
    line-height: 0;
    color: #fff;
    transform: translate(-50%, -50%);
    white-space: nowrap;
 }
 .text-l-arabic{
    position: absolute;
    font-size: 15px;
    font-family: "CorelaserArabic", sans-serif;
    color: #fff;
    transform: translate(-50%, -50%);
    white-space: nowrap;
 }
 .text-s-arabic{
    position: absolute;
    font-size: 4px;
    font-family: "CorelaserArabic", sans-serif;
    line-height: 0;
    color: #fff;
    transform: translate(-50%, -50%);
    white-space: nowrap;
 }
 .text-white{
  color: #fff;
 }
 .text-black{
  color: #000;
 }
 /* extra large screens */
@media only screen and (min-width: 1941px) {
  .image-box,
  .el-image {
    max-width: 650px;
  }
  .preTable{
    height: 301px;
  }
  .icon-image-l{
    font-size: 65px;
 }
 .icon-image-s{
    font-size: 30px;
 }
 .text-l{
    font-size: 32.5px;
 }
 .text-s{
    font-size: 10px;
 }
 .text-s-arabic{
    font-size: 10px;
 }
}
/* large screen */
@media only screen and (max-width: 1940px) {
  .image-box,
  .el-image {
    max-width: 400px;
  }
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .image-box,
  .el-image {
    max-width: 250px;
  }
  .icon-image-l{
    font-size: 25px;
 }
 .text-l{
    font-size: 12.5px;
 }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .image-box,
  .el-image {
    max-width: 250px;
  }
  .icon-image-l{
    font-size: 25px;
 }
 .text-l{
    font-size: 12.5px;
 }
}
/* animation */
 .v-enter-active,
 .v-leave-active {
   position: relative;
   transition: opacity 2s ease;
 }
 
 .v-enter-from,
 .v-leave-to {
   position: absolute;
   opacity: 0;
 }
  </style>
  