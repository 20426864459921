<template>
    <div class="image-box" :class="{ preTable: isActiveTableView, prePdf: isActivePdfView }">
      <Transition>
        <el-image v-if="fold == '7’’'" :key="touchPanel10inch[color]" :src=touchPanel7inch[color] fit="contain" style="position: absolute;"/> 
      </Transition>
      <Transition>
        <el-image v-if="fold == '10’’'" :key="touchPanel10inch[color]" :src=touchPanel10inch[color] fit="contain" style="position: absolute;"/>
      </Transition>
      <el-image v-if="fold == '7’’'" style="z-index: -1; opacity: 0.01;" :src=AluminumGrey7 fit="contain"/>
      <el-image v-if="fold == '10’’'" style="z-index: -1; opacity: 0.01;" :src=AluminumGrey10 fit="contain"/>

      <!-- for animation caching -->
      <div v-if="!isTableView">
        <div v-show="false" v-for="(panel,index) in touchPanel7inch" :key="index">
          <el-image :src=panel />
        </div>
        <div v-show="false" v-for="(panel,index) in touchPanel10inch" :key="index">
          <el-image :src=panel />
        </div>
      </div>

    </div>
</template>
  
  <script setup>
      // @ is an alias to /src
    import { ref, defineProps, computed } from 'vue'
    import { useStore } from 'vuex'
    //7inch
    import AluminumBlack7 from "@/assets/img/touch-panel/7inch/aluminum-black.png"
    import AluminumGrey7 from "@/assets/img/touch-panel/7inch/aluminum-grey.png"
    import Brass7 from "@/assets/img/touch-panel/7inch/brass.png"
    import CocoaGrey7 from "@/assets/img/touch-panel/7inch/cocoa-grey.png"
    import GlacierWhite7 from "@/assets/img/touch-panel/7inch/glacier-white.png"
    import ObsidianBlack7 from "@/assets/img/touch-panel/7inch/obsidian-black.png"
    import SandBeige7 from "@/assets/img/touch-panel/7inch/sand-beige.png"
    import StainlessSteel7 from "@/assets/img/touch-panel/7inch/stainless-steel.png"
    import TitaniumGrey7 from "@/assets/img/touch-panel/7inch/titanium-grey.png"
    import Verdant7 from "@/assets/img/touch-panel/7inch/verdant.png"
    //10inch
    import AluminumBlack10 from "@/assets/img/touch-panel/10inch/aluminum-black.png"
    import AluminumGrey10 from "@/assets/img/touch-panel/10inch/aluminum-grey.png"
    import Brass10 from "@/assets/img/touch-panel/10inch/brass.png"
    import CocoaGrey10 from "@/assets/img/touch-panel/10inch/cocoa-grey.png"
    import GlacierWhite10 from "@/assets/img/touch-panel/10inch/glacier-white.png"
    import ObsidianBlack10 from "@/assets/img/touch-panel/10inch/obsidian-black.png"
    import SandBeige10 from "@/assets/img/touch-panel/10inch/sand-beige.png"
    import StainlessSteel10 from "@/assets/img/touch-panel/10inch/stainless-steel.png"
    import TitaniumGrey10 from "@/assets/img/touch-panel/10inch/titanium-grey.png"
    import Verdant10 from "@/assets/img/touch-panel/10inch/verdant.png"

    const props = defineProps({
      fold: String,
      color: String,
      isTableView: Boolean,
      isPdfView: Boolean
    });

    const store = useStore();

    const show = ref(true)

    const view = computed(() => {
        return props.isTableView == 'true' ? 'max-width: 60%' : 'max-width: 34%'
    })
    const isActiveTableView = computed(() => {
        return props.isTableView
    })
    const isActivePdfView = computed(() => {
        return props.isPdfView
    })

    const touchPanel7inch = {
        '': AluminumBlack7,
        "Aluminum Black": AluminumBlack7,
        "Aluminum Grey": AluminumGrey7,
        "Brass": Brass7,
        "Cocoa Grey": CocoaGrey7,
        "Glacier White": GlacierWhite7,
        "Obsidian Black": ObsidianBlack7,
        "Sand Beige": SandBeige7,
        "Stainless Steel": StainlessSteel7,
        "Titanium Grey": TitaniumGrey7,
        "Verdant": Verdant7,
    };

    const touchPanel10inch = {
        '': AluminumBlack10,
        "Aluminum Black": AluminumBlack10,
        "Aluminum Grey": AluminumGrey10,
        "Brass": Brass10,
        "Cocoa Grey": CocoaGrey10,
        "Glacier White": GlacierWhite10,
        "Obsidian Black": ObsidianBlack10,
        "Sand Beige": SandBeige10,
        "Stainless Steel": StainlessSteel10,
        "Titanium Grey": TitaniumGrey10,
        "Verdant": Verdant10,
    };
  </script>

<style scoped>
.image-box {
  position: relative;
  margin: auto;
}
.preTable{
    height: 131px;
  }
  .prePdf{
    height: 151px;
  }
/* extra large screens */
@media only screen and (min-width: 1941px) {
  .el-image {
    max-width: 650px;
  }
  .preTable{
    height: 301px;
  }
}
/* large screen */
@media only screen and (max-width: 1940px) {
  .el-image {
    max-width: 400px;
  }
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .el-image {
    max-width: 250px;
  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .el-image {
    max-width: 250px;
  }
}
/* animation */
.v-enter-active,
 .v-leave-active {
   position: relative;
   transition: opacity 2s ease;
 }
 
 .v-enter-from,
 .v-leave-to {
   position: absolute;
   opacity: 0;
 }
</style>
  