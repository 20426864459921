export default {
  "Silky-Matt Finishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İpeksi Mat Yüzey"])},
  "Brushed Finishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satine Yüzey"])},
  "Select Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün Seçimi"])},
  "Select Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model Seçimi"])},
  "Select Icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon Seçimi"])},
  "Select Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materyal Seçimi"])},
  "Customize Your Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürününüzü Özelleştirin"])},
  "Your Configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigürasyon"])},
  "Surface Touch Panel Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface Touch Panel Seçimi"])},
  "Eclipse Touch Panel Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclipse Touch Panel Seçimi"])},
  "Room Terminal Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oda Terminal Seçimi"])},
  "Fold Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anahtar Seçimi"])},
  "Thermostat Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termostat Seçimi"])},
  "AC Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AC Markaları"])},
  "Project Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Bilgileri"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İndir"])},
  "Download Configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigürasyonu İndir"])},
  "Project Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Adı"])},
  "Company Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Adı"])},
  "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi"])},
  "Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili Kişi"])},
  "Please input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen değer girin"])},
  "Delete Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigürasyonu silinecek. Emin misiniz?"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
  "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uyarı"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
  "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygula"])},
  "Add New Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Ürün Ekle"])},
  "Configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yapılandırıcı"])},
  "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon"])},
  "StepProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün"])},
  "StepModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "StepMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materyal"])},
  "StepCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özelleştirme"])},
  "StepConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigürasyon"])},
  "No:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No:"])},
  "Photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön İzleme"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaylar"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adt"])},
  "Qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adet"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not"])},
  "Your notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notunuz"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje"])},
  "Project Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proje Adı gerekli"])},
  "Company Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma Adi gerekli"])},
  "Contact Person is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İlgili kişi gerekli"])},
  "Oops, fill in the required fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata, gerekli alanları doldurun"])},
  "Markers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markers"])},
  "Light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
  "Shutter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shutter"])},
  "Hvac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvac"])},
  "Scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scenes"])},
  "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "Multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multimedia"])},
  "Appliances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliances"])},
  "Insert text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert text..."])},
  "Write the text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metin giriniz (Max. 10 karakter)"])}
}