<template>
    <div class="common-layout">
      <el-container>
        <el-main>
          <el-row justify="center">
            <el-col :span="22">
              <el-table :data="productCart" :header-cell-style="{ color: 'black'}" :cell-style="{height: cellSize + 'px'}">
                  <el-table-column align="center" type="index" :label="`${$t('No:')}`" min-width="50" />
                  <el-table-column align="center" prop="name" :label="`${$t('Photo')}`" :width = cellSize>
                     <template #default="props">
                        <div class="preview">
                          <ClimateGateway v-if="props.row.name == 'climateGateway'" 
                            :fold=props.row.fold
                            :isTableView=true
                          />
                          <TouchPanel v-if="props.row.name == 'touchPanel'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=true
                          />
                          <PushButtonSwitch v-if="props.row.name == 'pushButtonSwitch'" 
                            :fold=props.row.fold 
                            :color=props.row.color 
                            :iconPack=props.row.iconPack 
                            iconSize="small" 
                            :iconColorCode=props.row.iconColorCode
                            :isTableView=true
                          />
                          <RoomTerminal v-if="props.row.name == 'roomTerminal'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=true 
                          />
                          <SurfacePanel v-if="props.row.name == 'surfacePanel'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=true
                          />
                          <Thermostat v-if="props.row.name == 'thermostat'" 
                            :fold="props.row.fold" 
                            :color="props.row.color" 
                            :knobColor="props.row.knobColor" 
                            :iconPack="props.row.iconPack"
                            iconSize="small"
                            :iconColorCode=props.row.iconColorCode
                            :isTableView=true
                          />
                        </div>
                      </template>
                  </el-table-column>
                  <el-table-column prop="codes" :label="`${$t('Details')}`" min-width="185">
                    <template #default="product">
                        <div v-if="product.row.name == 'climateGateway'">
                          <span class="details">{{product.row.codes.electronicPart.code}}</span>
                          <span class="details">{{product.row.codes.fold.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'touchPanel'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'roomTerminal'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'surfacePanel'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'pushButtonSwitch'">
                           <span class="details">{{product.row.codes.electronicPart.code}}</span>
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].location +1 }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].url == 'TXT' ? product.row.iconPack[0].url : product.row.iconPack[0].icon }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].url == 'TXT' ? product.row.iconPack[1].url : product.row.iconPack[1].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[2] != undefined || product.row.iconPack[3] != undefined">
                            <div v-if="product.row.iconPack[2].icon != 'M7' || product.row.iconPack[3].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[2] != undefined">{{ product.row.iconPack[2].location +1 }}</span>
                              <span v-if="product.row.iconPack[3] != undefined">{{ product.row.iconPack[3].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[2] != undefined">{{ product.row.iconPack[2].url == 'TXT' ? product.row.iconPack[2].url : product.row.iconPack[2].icon }}</span>
                              <span v-if="product.row.iconPack[3] != undefined">{{ product.row.iconPack[3].url == 'TXT' ? product.row.iconPack[3].url : product.row.iconPack[3].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[4] != undefined || product.row.iconPack[5] != undefined">
                            <div v-if="product.row.iconPack[4].icon != 'M7' || product.row.iconPack[5].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[4] != undefined">{{ product.row.iconPack[4].location +1 }}</span>
                              <span v-if="product.row.iconPack[5] != undefined">{{ product.row.iconPack[5].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[4] != undefined">{{ product.row.iconPack[4].url == 'TXT' ? product.row.iconPack[4].url : product.row.iconPack[4].icon }}</span>
                              <span v-if="product.row.iconPack[5] != undefined">{{ product.row.iconPack[5].url == 'TXT' ? product.row.iconPack[5].url : product.row.iconPack[5].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[6] != undefined || product.row.iconPack[7] != undefined">
                            <div v-if="product.row.iconPack[6].icon != 'M7' || product.row.iconPack[7].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[6] != undefined">{{ product.row.iconPack[6].location +1 }}</span>
                              <span v-if="product.row.iconPack[7] != undefined">{{ product.row.iconPack[7].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[6] != undefined">{{ product.row.iconPack[6].url == 'TXT' ? product.row.iconPack[6].url : product.row.iconPack[6].icon }}</span>
                              <span v-if="product.row.iconPack[7] != undefined">{{ product.row.iconPack[7].url == 'TXT' ? product.row.iconPack[7].url : product.row.iconPack[7].icon }}</span>
                            </div>
                           </div>
                           
                        </div>

                        <div v-if="product.row.name == 'thermostat'">
                           <span class="details">{{product.row.codes.electronicPart.code}}</span>
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                           <span class="details">{{product.row.codes.knob.code}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].location +1 }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].url == 'TXT' ? product.row.iconPack[0].url : product.row.iconPack[0].icon }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].url == 'TXT' ? product.row.iconPack[1].url : product.row.iconPack[1].icon }}</span>
                            </div>
                           </div>
                        </div>

                      </template>
                  </el-table-column>
                  <el-table-column prop="codes" min-width="310">
                    <template #default="product">
                        <div v-if="product.row.name == 'climateGateway'">
                          <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                          <span class="details">{{product.row.codes.fold.desc}}</span>
                        </div>
                        <div v-if="product.row.name == 'touchPanel'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'roomTerminal'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'surfacePanel'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'pushButtonSwitch'">
                          <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                          <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                          <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[2] != undefined || product.row.iconPack[3] != undefined">
                            <div v-if="product.row.iconPack[2].icon != 'M7' || product.row.iconPack[3].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[4] != undefined || product.row.iconPack[5] != undefined">
                            <div v-if="product.row.iconPack[4].icon != 'M7' || product.row.iconPack[5].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[6] != undefined || product.row.iconPack[7] != undefined">
                            <div v-if="product.row.iconPack[6].icon != 'M7' || product.row.iconPack[7].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                        </div>

                        <div v-if="product.row.name == 'thermostat'">
                           <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                           <span class="details">{{product.row.codes.knob.desc}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                        </div>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" prop="quantity" :label="`${$t('Quantity')}`" min-width="250">
                    <template #default="product">
                      <el-input-number v-model="product.row.quantity" :min="1" :max="999" @change="changedAmount" />
                      <el-button plain type="danger" class="delete-button" @click="openDeleteDialog(product, product.$index, product.row)">
                        <el-icon class="delete-icon"><Delete /></el-icon>
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="note" :label="`${$t('Note')}`" min-width="250">
                    <template #default="product">
                      <el-input
                        v-model="product.row.note"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        type="textarea"
                        :placeholder="`${$t('Your notes')}`"
                        maxlength="99"
                        show-word-limit
                      />
                    </template>
                  </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-button @click="addNewProduct" class="add-product-button" type="primary" size="large" color="#00BFD7" style="color: #fff;">{{ $t("Add New Product") }}</el-button>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </div>


    <el-dialog v-model="deleteDialogVisible" title="Warning" :width=deletePopUp center>
      <template #header>
            <h5 :class="titleClass">{{ $t("Warning") }}</h5>
        </template>
      <span class="delete-message">
        {{ $t("Delete Message") }}
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button round @click="deleteDialogVisible = false">{{ $t("No") }}</el-button>
          <el-button round @click="deleteProduct(selectedScope.$index)" type="primary" color="#00BFD7" style="color: #fff;">{{ $t("Yes") }}</el-button>
        </span>
      </template>
    </el-dialog>
  </template>
  
  <script setup>
    import { ref, onMounted, onUnmounted, computed } from 'vue'
    import { ElMessageBox } from 'element-plus'
    import {useRoute, useRouter} from 'vue-router'
    import { useStore } from 'vuex'
    import { PRODUCT_STEP, CUSTOMIZATION_STEP, CONFIGURATION_STEP, MATERIAL_STEP, EXPIRE_TIME, MODEL_STEP } from "@/store/constants"
    import { getCart, removeCart, getExpireDate, setCart } from "@/api/auth"
    import { translate, i18n } from '@/i18n';

    // @ is an alias to /src
    import ClimateGateway from "@/components/climateGateway/Preview.vue"
    import TouchPanel from "@/components/eclipseTouchPanel/Preview.vue"
    import PushButtonSwitch from "@/components/pushButtonSwitch/Preview.vue"
    import RoomTerminal from "@/components/roomTerminal/Preview.vue"
    import SurfacePanel from "@/components/surfaceTouchPanel/Preview.vue"
    import Thermostat from "@/components/thermostat/Preview.vue"
    import AluminumBlack7 from "@/assets/img/touch-panel/7inch/aluminum-black.png"

    const store = useStore();
    const router = useRouter();

    const windowSize = ref(window.innerWidth)
    const productCart = ref(store.getters.getCart);
    // const productCart = JSON.parse(getCart())

    const deleteDialogVisible = ref(false)
    const selectedScope = ref({})

    const noCustomizationStepProducts = [
      "surfacePanel","touchPanel", "roomTerminal"
    ]
    const onlyModelStepProducts = [
      "climateGateway"
    ]

    const cellSize = computed(() => {
      if(windowSize.value > 1940)
        return '325'
      else
        return '155'
    })

    const deletePopUp = computed(() => {
      if(windowSize.value > 1200)
        return '30%'
      else
        return '80%'

    })

    const changedAmount = (value) => {
      console.log(value)
    }

    const addNewProduct = () => {
      const expireDate = getExpireDate();
      if(expireDate < Date.now()){
      removeCart();
      store.commit("clearCart");
    }
      store.commit("setActiveStep", PRODUCT_STEP)
      router.push('/product')
    }

    const openDeleteDialog = (scope, index, row) => {
      console.log(scope, index, row)
      deleteDialogVisible.value = true;
      selectedScope.value = scope;
    }
    const deleteProduct = (index, row, scope) => {
      store.commit("deleteProduct", index)
      setCart(JSON.parse(JSON.stringify(store.getters.getCart)))
      deleteDialogVisible.value = false;
    }

    const getPreviousRoute = () => {
      if(onlyModelStepProducts.includes(store.getters.getProductName)){
        return "/model"
      }
      else if(noCustomizationStepProducts.includes(store.getters.getProductName)){
        return "/material"
      }
      else if(!noCustomizationStepProducts.includes(store.getters.getProductName)) {
        return "/customization"
      }
    }

    const getPreviousStep = () => {
      if(onlyModelStepProducts.includes(store.getters.getProductName)){
        return MODEL_STEP
      }
      else if(noCustomizationStepProducts.includes(store.getters.getProductName)){
        return MATERIAL_STEP
      }
      else if(!noCustomizationStepProducts.includes(store.getters.getProductName)) {
        return CUSTOMIZATION_STEP
      }
    }

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/configuration',
        previousRoute: store.getters.getProductName + getPreviousRoute(),
        // previousRoute: noCustomizationStepProducts.includes(store.getters.getProductName)  ? store.getters.getProductName + "/material" : store.getters.getProductName + "/customization",
        nextStep: CONFIGURATION_STEP,
        previousStep: getPreviousStep()
        // previousStep: noCustomizationStepProducts.includes(store.getters.getProductName)  ? MATERIAL_STEP : CUSTOMIZATION_STEP,
      });
      window.addEventListener('resize', () => {windowSize.value = window.innerWidth} )
    })
    onUnmounted(() => {
        window.removeEventListener('resize', () => {windowSize.value = window.innerWidth})
      })
  </script>

  <style scoped>
.el-button {
  font-family: 'SF-Compact-Rounded Medium';
}
.delete-button {
  display: contents;
}
.delete-button :hover{
  color: red;
}
.delete-icon {
  margin-left: 12px;
}
.preview {
  text-align: center;
  margin: auto;
}
.add-product-button {
  height: 2.5vw;
  width: 15vw;
  border-radius: 2vw;
  font-size: 1vw;
  min-height: 30px;
  min-width: 200px;
  background-color: rgba(0,191,215, 1);
}
.el-row {
  margin-bottom: 20px;
}
.el-table {
  font-size: 1vw;
}
.details {
  display: block;
  font-size: 0.75vw;
}
.delete-message {
  padding: 10px;
  font-size: 14px;
  display: block;
  text-align: center;
  font-family: 'SF-Compact-Rounded';
}

/* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .el-table {
    font-size: 1.25vw;
  }
  .details {
    font-size: 1.25vw;
  }
  .add-product-button {
    border-radius: 5vw;
    font-size: 1.25vw;

  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .el-table {
    font-size: 1.9vw;
  }
  .details {
    font-size: 1.9vw;
  }
  .add-product-button {
    border-radius: 5vw;
    font-size: 1.9vw;

  }
}
@media only screen and (max-width: 450px) {
  .el-table {
    font-size: 2.5vw;
  }
  .details {
    font-size: 2.5vw;
  }
  .add-product-button {
    border-radius: 5vw;
    font-size: 2.5vw;

  }
}
  </style>
  