<template>
  <div class="image-box" :class="{ preTable: isActiveTableView, prePdf: isActivePdfView }">
    <Transition>
      <el-image :key="colors[color]" :src=colors[color] fit="contain" style="position: absolute;"/>
    </Transition>
    <el-image style="z-index: -1; opacity: 0.01;" :src=AluminumGrey fit="contain"/>
  </div>

    <!-- for animation caching -->
    <div v-if="!isTableView">
      <div v-show="false" v-for="(color,index) in colors" :key="index">
        <el-image :src=color />
      </div>
    </div>
</template>
  
  <script setup>
    import { ref, defineProps, computed } from 'vue'
    import { useStore } from 'vuex'
    // @ is an alias to /src
    import AluminumBlack from "@/assets/img/room-terminal/colors/aluminum-black.png"
    import AluminumGrey from "@/assets/img/room-terminal/colors/aluminum-grey.png"
    import Brass from "@/assets/img/room-terminal/colors/brass.png"
    import CocoaGrey from "@/assets/img/room-terminal/colors/cocoa-grey.png"
    import GlacierWhite from "@/assets/img/room-terminal/colors/glacier-white.png"
    import ObsidianBlack from "@/assets/img/room-terminal/colors/obsidian-black.png"
    import SandBeige from "@/assets/img/room-terminal/colors/sand-beige.png"
    import StainlessSteel from "@/assets/img/room-terminal/colors/stainless-steel.png"
    import TitaniumGrey from "@/assets/img/room-terminal/colors/titanium-grey.png"
    import Verdant from "@/assets/img/room-terminal/colors/verdant.png"

    const props = defineProps({
      fold : String,
      color: String,
      isTableView: Boolean,
      isPdfView: Boolean
    });
    const store = useStore();

    const isActiveTableView = computed(() => {
        return props.isTableView
    })
    const isActivePdfView = computed(() => {
        return props.isPdfView
    })

    const colors = {
        '': AluminumBlack,
        "Aluminum Black": AluminumBlack,
        "Aluminum Grey": AluminumGrey,
        "Brass": Brass,
        "Cocoa Grey": CocoaGrey,
        "Glacier White": GlacierWhite,
        "Obsidian Black": ObsidianBlack,
        "Sand Beige": SandBeige,
        "Stainless Steel": StainlessSteel,
        "Titanium Grey": TitaniumGrey,
        "Verdant": Verdant,
    };
  </script>

<style scoped>
.image-box {
  position: relative;
  margin: auto;
}
.preTable{
    height: 131px;
  }
  .prePdf{
    height: 151px;
  }
/* extra large screens */
@media only screen and (min-width: 1941px) {
  .el-image {
    max-width: 650px;
  }
  .preTable{
    height: 301px;
  }
}
/* large screen */
@media only screen and (max-width: 1940px) {
  .el-image {
    max-width: 400px;
  }
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .el-image {
    max-width: 250px;
  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .el-image {
    max-width: 250px;
  }
}
/* animation */
.v-enter-active,
 .v-leave-active {
   position: relative;
   transition: opacity 2s ease;
 }
 
 .v-enter-from,
 .v-leave-to {
   position: absolute;
   opacity: 0;
 }
/* .v-enter-active,
 .v-leave-active {
  position: relative;
  transition: opacity 3s ease;
 }
 
 .v-enter-from,
 .v-leave-to {
   position: absolute;
   opacity: 0.1;
 }

 .v-enter-active, 
 .v-leave-active {
  transition-delay: 0.35s; 
 } */
</style>
  