export default {
  "Silky-Matt Finishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silky-Matt Finishes"])},
  "Brushed Finishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brushed Finishes"])},
  "Aluminum Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluminum Black"])},
  "Aluminum Grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aluminum Grey"])},
  "Brass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brass"])},
  "Stainless Steel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stainless Steel"])},
  "Cocoa Grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocoa Grey"])},
  "Glacier White": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glacier White"])},
  "Obsidian Black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsidian Black"])},
  "Sand Beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sand Beige"])},
  "Titanium Grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titanium Grey"])},
  "Verdant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdant"])},
  "Select Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Product"])},
  "Select Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Model"])},
  "Select Icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Icons"])},
  "Select Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Material"])},
  "Customize Your Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize Your Product"])},
  "Your Configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Configuration"])},
  "Surface Touch Panel Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surface Touch Panel Options"])},
  "Eclipse Touch Panel Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eclipse Touch Panel Options"])},
  "Room Terminal Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room Terminal Options"])},
  "Fold Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fold Options"])},
  "Thermostat Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thermostat Options"])},
  "AC Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AC Brands"])},
  "Project Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Information"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "Download Configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Configuration"])},
  "Project Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])},
  "Company Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
  "Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Name"])},
  "Please input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input"])},
  "Delete Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your configuration will be deleted. Are you sure?"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "Add New Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Product"])},
  "Configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurator"])},
  "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
  "StepProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "StepModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
  "StepMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material"])},
  "StepCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customization"])},
  "StepConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "No:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No:"])},
  "Photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "Qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "Your notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your notes"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "Project Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name is required"])},
  "Company Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name is required"])},
  "Contact Person is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person is required"])},
  "Oops, fill in the required fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, fill in the required fields"])},
  "Markers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markers"])},
  "Light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
  "Shutter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shutter"])},
  "Hvac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvac"])},
  "Scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scenes"])},
  "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "Multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multimedia"])},
  "Appliances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliances"])},
  "Insert text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert text..."])},
  "Write the text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the text (Max. 10 characters)"])}
}