import { createStore, storeKey } from 'vuex'
import product from './modules/product'
import navigation from './modules/navigation'

const store = createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    product,
    navigation
  }
})

export default store;
