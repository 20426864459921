<template>
    <!-- <select v-model="$i18n.locale">
      <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
        {{ locale }}
      </option>
    </select> -->
    <flag :iso=currentLang.countryCode />
    <el-select v-model="$i18n.locale" class="locale-switcher-select m-2" size="small">
      <el-option
        v-for="(locale, i) in locales"
        :key="`locale-${i}`"
        :label="locale.langCode"
        :value="locale.countryCode"
        @click="changedLanguage(locale)"
      >
      <flag :iso=locale.countryCode />
      <!-- <span>{{ locale.langCode }}</span> -->
    </el-option>

    </el-select>

    <!-- <el-dropdown @command="changedLanguage">
    <span class="el-dropdown-link">
      <flag :iso=currentLang.countryCode />
      <el-icon class="el-icon--right">
        <arrow-down />
      </el-icon>
    </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item v-for="(locale, i) in locales" :command=locale :key="`locale-${i}`">
          <flag :iso=locale.countryCode />
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown> -->
</template>
  
<script setup>
    const locales = [
      {countryCode: "gb", langCode: "en"},
      {countryCode: "tr", langCode: "tr"},
      {countryCode: "it", langCode: "it"},
  ];
    let currentLang = {countryCode: "gb", langCode: "en"}

    const changedLanguage = (locale) => {
      currentLang = locale;
    }
</script>
<style scoped>
@import "@/styles/locale-switcher.css";
.el-input__inner{
  display: none !important;
}
</style>