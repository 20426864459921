<template>
    <el-row justify="center">
        <el-col :span="24">
            <Preview :fold="store.getters.getFold" 
                     :color="store.getters.getColor"
                     :isTableView=false
                     />
        </el-col>
        <el-col class="product-desc" :span="10">
            <span>{{ store.getters.getProductCodes[FOLD].code }}</span><span>-</span><span>{{ store.getters.getProductCodes[COLOR].code }}</span>
        </el-col>
        <el-col class="product-desc" :span="10">
            <span>{{ store.getters.getProductCodes[FOLD].desc }}</span><span>-</span><span>{{ store.getters.getProductCodes[COLOR].desc }}</span>
        </el-col>
    </el-row>
</template>
  
  <script setup>
    import { ref } from 'vue'
    import { useStore } from 'vuex'
    // @ is an alias to /src
    import Preview from './Preview.vue'
    import { FOLD } from "@/store/constants"
    import { COLOR } from "@/store/constants"

    const store = useStore();

  </script>

  <style scoped>
 .product-desc {
    margin-top: 10px;
    font-size: 0.75vw;
 }
   /* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
}
/* mobile */
@media only screen and (max-width: 768px) {
 .product-desc {
    margin-top: 3.5vw;
    font-size: 2.5vw;
 }
}
  </style>
  