<template>
    <el-button class="btn-download center hidden-md-and-down" type="primary" link @click="showDialog">{{ $t("Download Configuration") }}</el-button>
    <el-button class="btn-download center hidden-lg-and-up" type="primary" link @click="showDialog">{{ $t("Download") }}</el-button>
    <el-dialog v-model="dialogVisible" :show-close="false" :width=windowWidth center>
        <template #header>
            <h5 :id="titleId" :class="titleClass">{{ $t("Project Information") }}<font-awesome-icon :icon=icon /></h5>
        </template>
        <el-form
            ref="formRef"
            :model="projectInformation"
            status-icon
            label-position="top"
            label-width="200px"
            class="info-form">
                <el-form-item
                :label="`${$t('Project Name')}`"
                prop="projectName"
                :rules="[
                    { required: true, message: `${$t('Project Name is required')}` },
                ]">
                <el-input
                    v-model="projectName"
                    maxlength="99"
                    show-word-limit
                    :placeholder="`${$t('Please input')}`"
                    type="text"
                    autocomplete="off"
                />
                </el-form-item>
                <el-form-item
                :label="`${$t('Company Name')}`"
                prop="companyName"
                :rules="[
                    { required: true, message: `${$t('Company Name is required')}` },
                ]">
                <el-input
                    v-model="companyName"
                    type="text"
                    autocomplete="off"
                    maxlength="99"
                    show-word-limit
                    :placeholder="`${$t('Please input')}`"
                />
                </el-form-item>
                <el-form-item
                :label="`${$t('Contact Person')}`"
                prop="contactPerson"
                :rules="[
                    { required: true, message: `${$t('Contact Person is required')}` },
                ]">
                <el-input
                    v-model="contactPerson"
                    type="text"
                    autocomplete="off"
                    maxlength="99"
                    show-word-limit
                    :placeholder="`${$t('Please input')}`"
                />
                </el-form-item>
                <el-form-item>
                    <el-button class="form-button" :disabled="generatingPdf" @click="dialogVisible = false" size="large" style="color: #606266;">{{ $t("Cancel") }}</el-button>
                    <el-button class="form-button" :loading="generatingPdf" :disabled="generatingPdf" @click="download()" type="primary" size="large" color="#00BFD7" style="color: #fff;">{{ $t("Download Configuration") }}</el-button>
                </el-form-item>
        </el-form>
    </el-dialog>
    <div class="pdf-content-wrapper">
      <PdfContent 
        :projectName="projectInformation.projectName" 
        :companyName="projectInformation.companyName" 
        :contactPerson="projectInformation.contactPerson" 
        :date="new Date().toLocaleDateString()" 
        :revNo="projectInformation.revNo"  />
   </div>
        
  
  </template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import { ElButton, ElDialog, ElMessage, FormInstance } from 'element-plus'
import PdfContent from '@/components/PdfContent.vue'
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import '@/assets/fonts/SF-Compact-Rounded-Regular-normal.js';


export default {
  name: "App",
  components: {
    PdfContent: PdfContent,
  },
  data() {
    return {
      exportFilename: new Date().toISOString().slice(0, 10).replaceAll('-', '') + "_planner",
      dialogVisible : false,
      windowSize : window.innerWidth,
      projectInformation: {
        projectName: '',
        companyName: '',
        contactPerson: '',
        revNo: 'Rev-00000'
      },
      generatingPdf: false,
    };
  },
  computed: {
    windowWidth() {
      return this.windowSize <= 1200 ? "80%" : "40%"
    },
    projectName: {
        get(){
            return this.projectInformation.projectName
        },
        set(projectName){
            this.projectInformation.projectName = projectName
        }
    },
    companyName: {
        get(){
            return this.projectInformation.companyName
        },
        set(companyName){
            this.projectInformation.companyName = companyName
        }
    },
    contactPerson: {
        get(){
            return this.projectInformation.contactPerson
        },
        set(contactPerson){
            this.projectInformation.contactPerson = contactPerson
        }
    }
  },
  methods: {
    showDialog() {
        this.dialogVisible = true;
    },
    async download() {
      const { projectName, companyName, contactPerson } = this.projectInformation;
      if (!projectName || !companyName || !contactPerson) {
        this.showErrorMessage();
        return;
      }
      this.generatingPdf = true;
      const fileName = new Date().toISOString().slice(0, 10).replaceAll('-', '') + "_"+ this.projectInformation.companyName + "_planner.pdf";
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        compress: true,
        hotfixes: ["px_scaling"],
      });

      const pdfContent = document.getElementById("pdfContent")
      const convertElements = pdfContent.getElementsByClassName("image-box")
      const elements = Array.from(convertElements);

      setTimeout(function () {
        Promise.all(
          elements.map(async (element) => {
            const canvas = await html2canvas(element, {
                  scale: 5,
            });
            element.replaceWith(canvas);
        }),
        ).then(() => {
          pdf.html(pdfContent, {
            callback: (generatedPdf) => {
              generatedPdf.save(fileName)
              this.$store.commit("setActiveStep", 5);
              this.dialogVisible = false
              this.generatingPdf = false
            },
          });
        })
        .catch(error => {
          console.log("error while generating pdf:", error)
          this.generatingPdf = false
        })
        this.loading = false
      }.bind(this),0)

    },
    showErrorMessage(){
      ElMessage.error('Oops, fill in the required fields')
      // ElMessage.error($t('Oops, fill in the required fields')
    }
  },
  mounted() {
    window.addEventListener('resize', () => {this.windowSize = window.innerWidth} )
  },
  beforeMount() {
    window.removeEventListener('resize', () => {this.windowSize = window.innerWidth})
  }
};

</script>

<style scoped>
@import 'element-plus/theme-chalk/display.css';
@import "@/styles/project-information.css";
.btn {
    position: absolute;
}
.btn-download {
  height: 7vh;
  color: #fff;
  font-size: 3.5vh;
  font-family: 'SF-Compact-Rounded Medium';
}
.form-button {
  height: 4vh;
  font-size: 1vw;
  border-radius: 5vw;
  font-family: 'SF-Compact-Rounded Medium';
}
.el-button.is-link:hover {
    color: #ddd;
}
.info-form {
    padding: 20px;
}
.pdf-content-wrapper {
  width: 800px;
}
.center {
  margin: auto;
  width: 60%;
  padding: 10px;
  color: #fff;
  font-size: 3.5vh;
  font-family: 'SF-Compact-Rounded Medium';
}
.el-form-item{
  font-family: 'SF-Compact-Rounded Medium';
}

/* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .form-button {
    font-size: 3.2vw;
  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .form-button {
    font-size: 2.2vw;
  }
}
</style>
