export default {
  "Silky-Matt Finishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finiture Opache"])},
  "Brushed Finishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finiture Spazzolate"])},
  "Select Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Prodotto"])},
  "Select Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Modello"])},
  "Select Icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Icone"])},
  "Select Material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Materiale"])},
  "Customize Your Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza il Tuo Prodotto"])},
  "Your Configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Tua Configurazione"])},
  "Surface Touch Panel Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni Panello Touch Surface"])},
  "Eclipse Touch Panel Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni Pannello Touch Eclipse"])},
  "Room Terminal Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni Room Controller"])},
  "Fold Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni Canali"])},
  "Thermostat Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni Termostato"])},
  "AC Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchi AC"])},
  "Project Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni Progetto"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])},
  "Download Configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica Configurazione"])},
  "Project Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Del Progetto"])},
  "Company Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Della Ditta"])},
  "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])},
  "Contact Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del Contatto"])},
  "Please input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di inserire"])},
  "Delete Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Tua Configurazione Verrà Eliminata. Sei Sicuro?"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosegui"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella"])},
  "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica"])},
  "Add New Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi Nuovo Prodotto"])},
  "Configurator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratore"])},
  "Icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icona"])},
  "StepProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodotto"])},
  "StepModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modello"])},
  "StepMaterial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiale"])},
  "StepCustomization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizza"])},
  "StepConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurazione"])},
  "No:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No:"])},
  "Photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
  "Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
  "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
  "Qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qtà"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
  "Your notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua nota"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progetto"])},
  "Project Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome del progetto è obbligatorio"])},
  "Company Name is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome dell'azienda è obbligatorio"])},
  "Contact Person is required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona di contatto è richiesta"])},
  "Oops, fill in the required fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops, compila i campi richiesti"])},
  "Markers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicatori"])},
  "Light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luci"])},
  "Shutter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorizzazioni"])},
  "Hvac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clima"])},
  "Scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scenari"])},
  "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo"])},
  "Multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multimedia"])},
  "Appliances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elettrodomestici"])},
  "Insert text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il testo..."])},
  "Write the text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivere il testo (Massimo 10 Caratteri)"])}
}