<template>
  <el-row>
    <el-col class="fold" :span="23" :offset="1">
         <span>{{ $t("Brushed Finishes") }}</span>
        <el-divider/>
    </el-col>
  </el-row>
  <el-row>
      <el-col :span="6" v-for="(color,index) in brushedFinishes" :key="index">
          <el-button plain @click="changedColor(color)">
              <el-image class="color" :src=color.url fit="contain"/>
              <el-image class="frame" v-if="color.name == store.getters.getColor" :src=Frame fit="contain" />
          </el-button>
          <span class="icon-label">{{ $t(color.name) }}</span>
      </el-col>
  </el-row>
  <el-row>
    <el-col class="fold" :span="23" :offset="1">
         <span>{{ $t("Silky-Matt Finishes") }}</span>
        <el-divider/>
    </el-col>
  </el-row>
  <el-row>
      <el-col :span="6" v-for="(color,index) in silkyMattFinishes" :key="index">
          <el-button plain @click="changedColor(color)">
              <el-image class="color" :src=color.url fit="contain"/>
              <el-image class="frame" v-if="color.name == store.getters.getColor" :src=Frame fit="contain" />
          </el-button>
          <!-- <span class="icon-label">{{ color.name }}</span> -->
            <span class="icon-label">{{ $t(color.name) }}</span>
      </el-col>
  </el-row>
</template>

<script setup>
  import { useStore } from 'vuex'
  // @ is an alias to /src
  import Frame from "@/assets/img/frame-material.png"
  //brushed-finishes
  import AluminumBlack from "@/assets/img/brushed-finishes/aluminum-black.png";
  import AluminumGrey from "@/assets/img/brushed-finishes/aluminum-grey.png";
  import Brass from "@/assets/img/brushed-finishes/brass.png";
  import StainlessSteel from "@/assets/img/brushed-finishes/stainless-steel.png";
  //silky-matt-finishes
  import CocoaGrey from "@/assets/img/silky-matt-finishes/cocoa-grey.png";
  import GlacierWhite from "@/assets/img/silky-matt-finishes/glacier-white.png";
  import ObsidianBlack from "@/assets/img/silky-matt-finishes/obsidian-black.png";
  import SandBeige from "@/assets/img/silky-matt-finishes/sand-beige.png";
  import TitaniumGrey from "@/assets/img/silky-matt-finishes/titanium-grey.png";
  import Verdant from "@/assets/img/silky-matt-finishes/verdant.png";

  import { COLOR } from "@/store/constants"
  const store = useStore();
  
  const brushedFinishes = [
      {code: "ALB", name: "Aluminum Black", url:AluminumBlack},
      {code: "ALG", name: "Aluminum Grey",url:AluminumGrey},
      {code: "BRA", name: "Brass",url:Brass},
      {code: "SST", name: "Stainless Steel",url:StainlessSteel}
  ];

  const silkyMattFinishes = [
      {code: "CCG", name: "Cocoa Grey", url:CocoaGrey},
      {code: "GLW", name: "Glacier White",url:GlacierWhite},
      {code: "OBL", name: "Obsidian Black",url:ObsidianBlack},
      {code: "SBE", name: "Sand Beige",url:SandBeige},
      {code: "TTG", name: "Titanium Grey",url:TitaniumGrey},
      {code: "VRD", name: "Verdant",url:Verdant}
  ];

  const lightColors = [
      "Aluminum Grey","Stainless Steel", "Glacier White", "Sand Beige"
  ]

  const changedColor = (color) => { 
      store.commit("setColor", color.name);
      store.commit("setIconColorCode", lightColors.includes(color.name)  ? '#000' : '#fff');
      store.commit("addProductCode",{index: COLOR, code: color.code, desc: color.name})
  }

</script>

<style scoped>
.el-button {
  position: relative;
  border: none;
  height: 5vw;
  width: 5vw;
  margin-bottom: 5px;
}
.color {
  width: 3vw;
}
.frame {
  display: inline;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 5px;
}
.left {
  text-align: left;
}
.icon-label {
  display: block;
  font-size: 0.9vw;
}
.fold {
  text-align: left;
  font-size: 1.8vw;
  margin-top: 1.6vw;
}
.el-divider{
margin-top: 10px;
}
/* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
}
/* mobile */
@media only screen and (max-width: 768px) {
  .el-button {
    height: 15vw;
    width: 15vw;
  }
  .color {
    width: 9vw;
  }
  .icon-label {
    display: block;
    font-size: 2.5vw;
  }
  .fold {
    font-size: 4vw;
    margin-top: 7vw;
  }
}
</style>
