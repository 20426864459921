<template>
    <Materials/>
</template>
  
  <script setup>
    import Materials from '../Materials.vue'
    import { COLOR, MODEL_STEP, CONFIGURATION_STEP } from "@/store/constants"

    import {onMounted} from 'vue'
    import { useStore } from 'vuex'

    const store = useStore();

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/configuration',
        previousRoute: '/roomTerminal/model',
        nextStep: CONFIGURATION_STEP,
        previousStep: MODEL_STEP
      });
      if(store.getters.getColor == ''){
        store.commit("setColor", "Aluminum Black");
        store.commit("addProductCode",{index: COLOR, code: "ALB", desc: "Aluminum Black"})
      }
    })
  </script>

  <style scoped>
  </style>
  