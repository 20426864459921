<template>
    <div class="common-layout">
      <el-container>
        <el-main>
            <el-row justify="center">
              <el-col :span="22">
            <Carousel :itemsToShow=sliderCount :wrapAround="false" :transition="500" :snapAlign="end">
              <Slide key="surfacePanel">
                <el-col :xs="{span: 20, offset: 0}" :span="24">
                      <el-card @click="changedProduct('surfacePanel')" shadow="never">
                        <div class="image-box" :class="{ opa100: 'surfacePanel' == store.getters.getProductName }">
                          <el-image :src=SurfaceTouchPanel class="product" fit="contain" />
                          <el-image v-if="'surfacePanel' == store.getters.getProductName" class="frame" :src=TouchPanelFrame fit="contain" />
                        </div>
                      </el-card>
                      <div class="product-name">
                        <span>Surface <br> Touch Panel</span>
                      </div>
                  </el-col>
              </Slide>
              <Slide key="touchPanel">
                <el-col :xs="{span: 20, offset: 0}" :span="24">
                    <el-card @click="changedProduct('touchPanel')" shadow="never">
                      <div class="image-box" :class="{ opa100: 'touchPanel' == store.getters.getProductName }">
                        <el-image :src=EclipseTouchPanel class="product" fit="contain" />
                        <el-image v-if="'touchPanel' == store.getters.getProductName" class="frame" :src=TouchPanelFrame fit="contain" />
                      </div>
                    </el-card>
                    <div class="product-name">
                      <span>Eclipse <br> Touch Panel</span>
                    </div>
                </el-col>
              </Slide>
              <Slide key="roomTerminal">
                <el-col :xs="{span: 20, offset: 0}" :span="24">
                    <el-card @click="changedProduct('roomTerminal')" shadow="never">
                      <div class="image-box" :class="{ opa100: 'roomTerminal' == store.getters.getProductName }">
                        <el-image :src=EclipseRoomTerminal class="product"  fit="contain" />
                        <el-image v-if="'roomTerminal' == store.getters.getProductName" class="frame" :src=Frame fit="contain" />
                      </div>
                    </el-card>
                    <div class="product-name">
                      <span>Eclipse <br> Room Terminal</span>
                    </div>
                </el-col>
              </Slide>
              <Slide key="pushButtonSwitch">
                <el-col :xs="{span: 20, offset: 0}" :span="24">
                  <el-card @click="changedProduct('pushButtonSwitch')" shadow="never">
                    <div class="image-box" :class="{ opa100: 'pushButtonSwitch' == store.getters.getProductName }">
                      <el-image :src=EclipsePushButtonSwitch class="product" fit="contain" />
                      <el-image v-if="'pushButtonSwitch' == store.getters.getProductName" class="frame" :src=Frame fit="contain" />
                    </div>
                  </el-card>
                  <div class="product-name">
                      <span>Eclipse <br> Push-Button Switch</span>
                  </div>
                </el-col>
              </Slide>
              <Slide key="thermostat">
                <el-col :xs="{span: 20, offset: 0}" :span="24">
                  <el-card @click="changedProduct('thermostat')" shadow="never">
                    <div class="image-box" :class="{ opa100: 'thermostat' == store.getters.getProductName }">
                      <el-image :src=EclipseThermostat class="product" fit="contain" />
                      <el-image v-if="'thermostat' == store.getters.getProductName" class="frame" :src=Frame fit="contain" />
                    </div>
                  </el-card>
                  <div class="product-name">
                     <span>Eclipse <br> Thermostat</span>
                  </div>
                </el-col>
              </Slide>
              <Slide key="climateGateway">
                <el-col :xs="{span: 20, offset: 0}" :span="24">
                      <el-card @click="changedProduct('climateGateway')" shadow="never">
                        <div class="image-box" :class="{ opa100: 'climateGateway' == store.getters.getProductName }">
                          <el-image :src=ClimateGateway class="product" fit="contain" />
                          <el-image v-if="'climateGateway' == store.getters.getProductName" class="frame" :src=TouchPanelFrame fit="contain" />
                        </div>
                      </el-card>
                      <div class="product-name">
                        <span>Climate <br> Gateway</span>
                      </div>
                  </el-col>
              </Slide>
              <template #addons>
                <Pagination />
                <Navigation />
              </template>
            </Carousel>

          </el-col>
            </el-row>
        </el-main>
      </el-container>
    </div>
  </template>
  
  <script setup>
    import { ref, onMounted, onUnmounted, computed } from 'vue'
    import { useStore } from 'vuex'
    import { ElMessage } from 'element-plus'
    import { PRODUCT_STEP, MODEL_STEP, EXPIRE_TIME } from "@/store/constants"
    import { Carousel, Pagination, Slide, Navigation  } from 'vue3-carousel'
    import { setExpireDate, getCart } from "@/api/auth"
    // @ is an alias to /src
    import SurfaceTouchPanel from "../assets/img/surface-touch-panel.png"
    import EclipseTouchPanel from "../assets/img/eclipse-touch-panel.png";
    import EclipseRoomTerminal from "../assets/img/eclipse-room-terminal.png";
    import EclipsePushButtonSwitch from "../assets/img/eclipse-push-button-switch.png";
    import EclipseThermostat from "../assets/img/eclipse-thermostat.png";
    import ClimateGateway from "../assets/img/climate-gateway.png"
    import Frame from "../assets/img/frame.png"
    import TouchPanelFrame from "../assets/img/touch-panel-frame.png"

    const store = useStore();

    const selectedProduct = ref('');
    const windowSize = ref(window.innerWidth)

    const sliderCount = computed(() => {
      if(windowSize.value <= 768)
        return 1
      else if(windowSize.value <= 1200)
        return 3
      else
        return 4
    })

    const changedProduct = (product) => { 
      store.commit("setProductName", product)
      selectedProduct.value = product
      store.commit("setNavigation", {
        nextRoute: product + "/model",
        previousRoute: '',
        nextStep: MODEL_STEP,
        previousStep: PRODUCT_STEP
      });

    }

    const resetOptions = () => {
      store.commit("setProductName",'')
      store.commit("setColor",'')
      store.commit("setIconColorCode",'')
      store.commit("setFold",'')
      store.commit("setKnobColor",'')
      store.commit("clearIconPack")
      store.commit("clearProductCodes")
      store.commit("setActiveStep", PRODUCT_STEP)
    }

    onMounted(() => {
      resetOptions();
      setExpireDate(Date.now() + EXPIRE_TIME)
      window.addEventListener('resize', () => {windowSize.value = window.innerWidth} )
    })

    onUnmounted(() => {
      window.removeEventListener('resize', () => {windowSize.value = window.innerWidth})
    })
  </script>


  
  <style scoped>
@import "@/styles/slider.css";
  .image-box {
    opacity: 60%;
  }

  .opa100 {
    opacity: 100%;
  }

  .frame {
    display: inline;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .el-card{
    border: none;
    position: relative;
  }
  .product {
    padding: 0.75vw;
  }
  .product-name {
    font-size: 1.5vw;
    margin-top: 0.8vw;
  }

  img {
    opacity: 60%;
  }
  /* extra large screens */
@media only screen and (min-width: 1941px) {
  .product {
    height: 15vw;
  }
}
/* large screen */
@media only screen and (max-width: 1940px) {
  .product {
    height: 17vw;
  }
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .product {
    height: 24vw;
  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .product {
    padding: 1.5vw;
    height: 35vw;
  }
  .product-name {
    font-size: 3.5vw;
    margin-top: 1.6vw;
  }
}
  </style>
  