<template>
    <div class="common-layout">
      <el-container>
        <el-main>
            <el-row :gutter="60">
                <el-col :xs="{span: 24, offset: 0}" :span="10" :offset="1">
                  <router-view name="options"/>
                </el-col>
                <el-col :xs="{span: 24, offset: 0}" class="margin-top" :span="10" :offset="2">
                  <router-view name="details"/>
                </el-col>
            </el-row>
        </el-main>
      </el-container>
    </div>
  </template>
  
  <script setup>
    import { ref, onMounted} from 'vue'
    import { useStore } from 'vuex'
    // @ is an alias to /src

    const store = useStore();
  </script>

  <style scoped>
  .margin-top {
    margin-top: 4.5vw;
  }
  .el-button {
    position: relative;
    border: none;
    height: 120px;
    width: 120px;
    margin-bottom: 5px;
  }
 .icon-label {
    margin-left: 10px;
 }
 .product-desc {
    margin-top: 10px;
    font-size: 14px;
 }
 .frame {
    display: inline;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  .left {
    text-align: left;
  }
  </style>
  