<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <Header/>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
      <el-footer>
        <Footer/>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import Header from '@/views/Header.vue'
import Footer from '@/views/Footer.vue'

import { EXPIRE_TIME } from "@/store/constants"
import { onMounted } from 'vue'
import { setExpireDate, getCart, getExpireDate, removeCart } from "@/api/auth"
import store from './store/store'

const expireDate = getExpireDate();

  onMounted(() => {
    if(getExpireDate)
      if(expireDate < Date.now()){
        setExpireDate(Date.now() + EXPIRE_TIME)
        removeCart();
      }
    if(getCart() !== null)
      store.commit("setCart", getCart())
    })
</script>

<style>
body {
  height: 100vh;
  margin: 0;
}

#app {
  font-family: "SF-Compact-Rounded", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.el-header{
  height: auto;
}

.el-main {
  overflow: hidden;
  padding: 0px;
  margin-bottom: 4vh;
}
.el-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7vh;
  text-align: center;
  z-index: 999;
  padding: 0px;
  line-height: 2;
}
</style>
