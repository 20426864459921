<template>
  <el-row justify="end">
    <el-col class="local-switcher" :span="5">
      <LocalSwitcher class="switcher"/>
    </el-col>
  </el-row>
  <el-row style="height: 10vh">
    <el-col class="header" :span="4" :offset="1">
      <div @click="router.push('/product');" class="grid-content ep-bg-purple header-content">
        <el-button link><el-image class="logo" :src=CoreLogo fit="contain" /></el-button>
      </div>
    </el-col>
    <el-col class="header" :span="4" :offset="14">
      <div class="grid-content config header-content right">
        <span>{{ $t("Configurator") }}</span>
      </div>
    </el-col>
    <el-col :span="22" :offset="1">
      <el-divider/>
    </el-col>

  </el-row>
</template>

<script setup>
import {useRouter} from 'vue-router'
import CoreLogo from "../assets/img/core-logo-black.png";

import LocalSwitcher from "@/components/LocaleSwitcher.vue"

const router = useRouter();
</script>

 <style scoped>
/* .el-row {
  margin-top: 1vh;
}  */
.header {
  position: relative;
  min-height: 50px;
}
.header-content {
  position: absolute;
  bottom: 0;
}
.logo {
  width: 7vw;
}
.right {
  right: 0;
}
.el-divider{
  margin-top: 5px;
}
.config{
opacity: 1;
color: rgba(0,0,0,1);
font-size: 2vw;
font-weight: 300;
font-style: normal;
letter-spacing: 0px;
text-align: left;
}
.m0{
  margin: 0;
}
.switcher{
  position: absolute;
  right: 0;
  top: 0;
}
.local-switcher{
  margin-top: 1vh;
  text-align: right;
}

/* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
}
/* mobile */
@media only screen and (max-width: 768px) {
  .logo {
    width: 14vw;
  }
  .config{
    font-size: 4vw;
    }
}
</style>
