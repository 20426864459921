import Vue from "vue";

const product = {
    state: {
        product : {
            name: '',
            fold: '',
            color: '',
            iconColorCode: '',
            knobColor: '',
            iconPack: [],
            quantity: 1,
            note: '',
            codes: {
              electronicPart: {
                code: '',
                desc: ''
              },
              fold: {
                code: '',
                desc: '',
              },
              color: {
                code: '',
                desc: '',
              },
              knob: {
                code: '',
                desc: '',
              },
              customEngraving: []
            },
          },
          cart: []
    },
    getters: {
        getProductName: state => {return state.product.name},
        getColor: state => {return state.product.color},
        getIconColorCode: state => {return state.product.iconColorCode},
        getFold: state => {return state.product.fold},
        getKnobColor: state => {return state.product.knobColor},
        getIconPack: state => {return state.product.iconPack},
        getProduct: state => {return state.product},
        getCart: state => {return state.cart},
        getProductCodes: state => {return state.product.codes},
    },
    mutations: {
        setProductName: (state, product) => {
            state.product.name = product;
          },
          setColor: (state, color) => {
            state.product.color = color;
          },
          setIconColorCode: (state, colorCode) => {
            state.product.iconColorCode = colorCode;
          },
          setFold: (state, fold) => {
            state.product.fold = fold;
          },
          setKnobColor: (state, knobColor) => {
            state.product.knobColor = knobColor;
          },
          setIconPack: (state, payload) => {
            state.product.iconPack[payload.index] = payload
          },
          clearIconPack: (state) => {
            state.product.iconPack = [];
          },
          clearProductCodes: (state) => {
            state.product.codes = {
              electronicPart: {
                code: '',
                desc: ''
              },
              fold: {
                code: '',
                desc: '',
              },
              color: {
                code: '',
                desc: '',
              },
              knob: {
                code: '',
                desc: '',
              },
              customEngraving: []
            } 
          },
          addProduct: (state) => {
            state.cart.push(JSON.parse(JSON.stringify(state.product)))
          },
          popProduct: (state) => {
            state.cart.pop()
          },
          clearCart: (state) => {
            state.cart = [];
          },
          setCart: (state, payload) => {
            state.cart = JSON.parse(payload);
          },
          deleteProduct: (state ,index) => {
            if(state.cart.length > 0) {
              state.cart.splice(index,1)
            }
          },
          addProductCode: (state,payload) => {
            state.product.codes[payload.index] = payload
          }
    },
    actions: {
    },

}

export default product;