<template>
  <el-row justify="center">
    <el-col>
      <div class="home">
        <el-steps :active="$store.getters.getActiveStep" finish-status="success" status="process" align-center>
          <el-step v-if="windowSize > 768" :title="`${$t('StepProduct')}`" /> <el-step v-else />
          <el-step v-if="windowSize > 768" :title="`${$t('StepModel')}`" /> <el-step v-else />
          <el-step v-if="windowSize > 768" :title="`${$t('StepMaterial')}`" /> <el-step v-else />
          <el-step v-if="windowSize > 768" :title="`${$t('StepCustomization')}`" /> <el-step v-else />
          <el-step v-if="windowSize > 768" :title="`${$t('StepConfiguration')}`" /> <el-step v-else />
        </el-steps>
      </div>
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <div class="title">
        <span v-if="$store.getters.getActiveStep === PRODUCT_STEP">{{ $t("Select Product") }}</span>
        <span v-if="$store.getters.getActiveStep === MODEL_STEP">{{ $t("Select Model") }}</span>
        <span v-if="$store.getters.getActiveStep === MATERIAL_STEP">{{ $t("Select Material") }}</span>
        <span v-if="$store.getters.getActiveStep === CUSTOMIZATION_STEP">{{ $t("Customize Your Product") }}</span>
        <span v-if="$store.getters.getActiveStep === CONFIGURATION_STEP || $store.getters.getActiveStep === COMPLETE_STEP">{{ $t("Your Configuration") }}</span>
      </div>
    </el-col>
  </el-row>
  <el-row style="margin-top: 0.3vw;">
    <el-col>
      <router-view/>
    </el-col>
  </el-row>
  <!-- <router-view/> -->
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
// @ is an alias to /src
import { PRODUCT_STEP, MODEL_STEP, MATERIAL_STEP, CUSTOMIZATION_STEP, CONFIGURATION_STEP, COMPLETE_STEP } from "@/store/constants"

const windowSize = ref(window.innerWidth)

onMounted(() => {
    window.addEventListener('resize', () => {windowSize.value = window.innerWidth} )
  })
onUnmounted(() => {
    window.removeEventListener('resize', () => {windowSize.value = window.innerWidth})
  })
</script>

<style scoped>
.el-steps{
  padding: 1vw 0 2vw 0;
}
.title {
  margin-top: 0.4vw;
  font-size: 1.8vw;
  font-family: 'SF-Compact-Rounded Medium';
}
/* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
}
/* mobile */
@media only screen and (max-width: 768px) {
  .title {
    margin-top: 7vw;
    font-size: 4vw;
  }
}
</style>
