<template>
    <el-button class="loc-button" type="info" @click="dialogVisible = true" :style="{'top': top + '%', 'left': left + '%'}">
        {{ $t("Icon") }} {{index+1}}
    </el-button>
    <el-dialog v-model="dialogVisible" :show-close="false" :width=windowWidth center>
        <template #header>
            <h4 :id="titleId" :class="titleClass">{{ $t("Icon") }} {{index+1}} <font-awesome-icon :icon=icon /></h4>
        </template>

        <el-tabs v-model="activeName" class="icon-tabs" stretch="true" @tab-click="handleClick">
            <el-tab-pane :label="`${$t('Markers')}`" name="markers">
                <el-row>
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(marker,index) in markers" :key="index">
                        <el-button class="icon-button" plain @click="changedIcon(marker.name, marker.url)">
                            <el-image class="icon" :src=marker.url fit="contain"/>
                            <el-image v-if="selectedIcon == marker.name" class="frame" :src=FrameBlue fit="contain" />
                            <el-image v-else class="frame" :src=FrameGrey fit="contain" />
                        </el-button>
                        <span class="icon-label">{{ marker.name }}</span>
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane :label="`${$t('Light')}`" name="light">
                <el-row>
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(light_item,index) in light" :key="index">
                        <el-button class="icon-button" plain @click="changedIcon(light_item.name, light_item.url)">
                            <el-image class="icon" :src=light_item.url fit="contain"/>
                            <el-image v-if="selectedIcon == light_item.name" class="frame" :src=FrameBlue fit="contain" />
                            <el-image v-else class="frame" :src=FrameGrey fit="contain" />
                        </el-button>
                        <span class="icon-label">{{ light_item.name }}</span>
                    </el-col>
                </el-row>
            </el-tab-pane>  

            <el-tab-pane :label="`${$t('Shutter')}`" name="shutter">
                <el-row>
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(shutter_item,index) in shutter" :key="index">
                        <el-button class="icon-button" plain @click="changedIcon(shutter_item.name, shutter_item.url)">
                            <el-image class="icon" :src=shutter_item.url fit="contain"/>
                            <el-image v-if="selectedIcon == shutter_item.name" class="frame" :src=FrameBlue fit="contain" />
                            <el-image v-else class="frame" :src=FrameGrey fit="contain" />
                        </el-button>
                        <span class="icon-label">{{ shutter_item.name }}</span>
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane :label="`${$t('Hvac')}`" name="hvac">
                <el-row>
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(hvac_item,index) in hvac" :key="index">
                        <el-button class="icon-button" plain @click="changedIcon(hvac_item.name, hvac_item.url)">
                            <el-image class="icon" :src=hvac_item.url fit="contain"/>
                            <el-image v-if="selectedIcon == hvac_item.name" class="frame" :src=FrameBlue fit="contain" />
                            <el-image v-else class="frame" :src=FrameGrey fit="contain" />
                        </el-button>
                        <span class="icon-label">{{ hvac_item.name }}</span>
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane :label="`${$t('Scenes')}`" name="scenes">
                <el-row>
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(scene,index) in scenes" :key="index">
                        <el-button class="icon-button" plain @click="changedIcon(scene.name, scene.url)">
                            <el-image class="icon" :src=scene.url fit="contain"/>
                            <el-image v-if="selectedIcon == scene.name" class="frame" :src=FrameBlue fit="contain" />
                            <el-image v-else class="frame" :src=FrameGrey fit="contain" />
                        </el-button>
                        <span class="icon-label">{{ scene.name }}</span>
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane :label="`${$t('Text')}`" name="text">
                <el-row>
                    <el-col>
                        {{ $t('Write the text') }}
                    </el-col>
                </el-row>
                <el-row justify="center">
                    <el-col :xs="22" :sm="16" :md="12" :lg="7">
                        <el-input
                            v-model="text"
                            maxlength="10"
                            :label="`${$t('Insert text')}`"
                            show-word-limit
                            type="text"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane :label="`${$t('Multimedia')}`" name="multimedia">
                <el-row>
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(multimedia_item,index) in multimedia" :key="index">
                        <el-button class="icon-button" plain @click="changedIcon(multimedia_item.name, multimedia_item.url)">
                            <el-image class="icon" :src=multimedia_item.url fit="contain"/>
                            <el-image v-if="selectedIcon == multimedia_item.name" class="frame" :src=FrameBlue fit="contain" />
                            <el-image v-else class="frame" :src=FrameGrey fit="contain" />
                        </el-button>
                        <span class="icon-label">{{ multimedia_item.name }}</span>
                    </el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane :label="`${$t('Appliances')}`" name="appliances">
                <el-row>
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" v-for="(appliance,index) in appliances" :key="index">
                        <el-button class="icon-button" plain @click="changedIcon(appliance.name, appliance.url)">
                            <el-image class="icon" :src=appliance.url fit="contain"/>
                            <el-image v-if="selectedIcon == appliance.name" class="frame" :src=FrameBlue fit="contain" />
                            <el-image v-else class="frame" :src=FrameGrey fit="contain" />
                        </el-button>
                        <span class="icon-label">{{ appliance.name }}</span>
                    </el-col>
                </el-row>
            </el-tab-pane>

        </el-tabs>

        <template #footer>
            <span class="dialog-footer">
                <el-button round @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
                <el-button @click="confirm" round color="#00BFD7" style="color: #fff;" >
                    {{ $t("Apply") }}
                </el-button>
            </span>
        </template>
    </el-dialog>
  </template>

<script setup>
import { ref, defineProps, onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import { ElDialog, ElMessage } from 'element-plus'
//frames
import FrameBlue from '@/assets/img/icons/frames/frame-blue.png'
import FrameGrey from '@/assets/img/icons/frames/frame-grey.png'
//appliances
import AP1 from '@/assets/img/icons/appliances/AP1.svg'
import AP2 from '@/assets/img/icons/appliances/AP2.svg'
import AP3 from '@/assets/img/icons/appliances/AP3.svg'
import AP4 from '@/assets/img/icons/appliances/AP4.svg'
import AP5 from '@/assets/img/icons/appliances/AP5.svg'
import AP6 from '@/assets/img/icons/appliances/AP6.svg'
import AP7 from '@/assets/img/icons/appliances/AP7.svg'
import AP8 from '@/assets/img/icons/appliances/AP8.svg'
import AP9 from '@/assets/img/icons/appliances/AP9.svg'
//hvac
import HV1 from '@/assets/img/icons/hvac/HV1.svg'
import HV2 from '@/assets/img/icons/hvac/HV2.svg'
import HV3 from '@/assets/img/icons/hvac/HV3.svg'
import HV4 from '@/assets/img/icons/hvac/HV4.svg'
import HV5 from '@/assets/img/icons/hvac/HV5.svg'
import HV6 from '@/assets/img/icons/hvac/HV6.svg'
import HV7 from '@/assets/img/icons/hvac/HV7.svg'
import HV8 from '@/assets/img/icons/hvac/HV8.svg'
import HV9 from '@/assets/img/icons/hvac/HV9.svg'
import HV10 from '@/assets/img/icons/hvac/HV10.svg'
import HV11 from '@/assets/img/icons/hvac/HV11.svg'
import HV12 from '@/assets/img/icons/hvac/HV12.svg'
import HV13 from '@/assets/img/icons/hvac/HV13.svg'
import HV14 from '@/assets/img/icons/hvac/HV14.svg'
import HV15 from '@/assets/img/icons/hvac/HV15.svg'
import HV16 from '@/assets/img/icons/hvac/HV16.svg'
import HV17 from '@/assets/img/icons/hvac/HV17.svg'
import HV18 from '@/assets/img/icons/hvac/HV18.svg'
//light
import L1 from '@/assets/img/icons/light/L1.svg'
import L2 from '@/assets/img/icons/light/L2.svg'
import L3 from '@/assets/img/icons/light/L3.svg'
import L4 from '@/assets/img/icons/light/L4.svg'
import L5 from '@/assets/img/icons/light/L5.svg'
import L6 from '@/assets/img/icons/light/L6.svg'
import L7 from '@/assets/img/icons/light/L7.svg'
import L8 from '@/assets/img/icons/light/L8.svg'
import L9 from '@/assets/img/icons/light/L9.svg'
import L10 from '@/assets/img/icons/light/L10.svg'
import L11 from '@/assets/img/icons/light/L11.svg'
import L12 from '@/assets/img/icons/light/L12.svg'
import L13 from '@/assets/img/icons/light/L13.svg'
import L14 from '@/assets/img/icons/light/L14.svg'
import L15 from '@/assets/img/icons/light/L15.svg'
import L16 from '@/assets/img/icons/light/L16.svg'
import L17 from '@/assets/img/icons/light/L17.svg'
import L18 from '@/assets/img/icons/light/L18.svg'
import L19 from '@/assets/img/icons/light/L19.svg'
import L20 from '@/assets/img/icons/light/L20.svg'
//markers
import M1 from '@/assets/img/icons/markers/M1.svg'
import M2 from '@/assets/img/icons/markers/M2.svg'
import M3 from '@/assets/img/icons/markers/M3.svg'
import M4 from '@/assets/img/icons/markers/M4.svg'
import M5 from '@/assets/img/icons/markers/M5.svg'
import M6 from '@/assets/img/icons/markers/M6.svg'
import M7 from '@/assets/img/icons/markers/M7.svg'
//multimedia
import MM1 from '@/assets/img/icons/multimedia/MM1.svg'
import MM2 from '@/assets/img/icons/multimedia/MM2.svg'
import MM3 from '@/assets/img/icons/multimedia/MM3.svg'
import MM4 from '@/assets/img/icons/multimedia/MM4.svg'
import MM5 from '@/assets/img/icons/multimedia/MM5.svg'
import MM6 from '@/assets/img/icons/multimedia/MM6.svg'
import MM7 from '@/assets/img/icons/multimedia/MM7.svg'
import MM8 from '@/assets/img/icons/multimedia/MM8.svg'
import MM9 from '@/assets/img/icons/multimedia/MM9.svg'
import MM10 from '@/assets/img/icons/multimedia/MM10.svg'
import MM11 from '@/assets/img/icons/multimedia/MM11.svg'
//scenes
import SC1 from '@/assets/img/icons/scenes/SC1.svg'
import SC2 from '@/assets/img/icons/scenes/SC2.svg'
import SC3 from '@/assets/img/icons/scenes/SC3.svg'
import SC4 from '@/assets/img/icons/scenes/SC4.svg'
import SC5 from '@/assets/img/icons/scenes/SC5.svg'
import SC6 from '@/assets/img/icons/scenes/SC6.svg'
import SC7 from '@/assets/img/icons/scenes/SC7.svg'
import SC8 from '@/assets/img/icons/scenes/SC8.svg'
import SC9 from '@/assets/img/icons/scenes/SC9.svg'
import SC10 from '@/assets/img/icons/scenes/SC10.svg'
import SC11 from '@/assets/img/icons/scenes/SC11.svg'
import SC12 from '@/assets/img/icons/scenes/SC12.svg'
import SC13 from '@/assets/img/icons/scenes/SC13.svg'
import SC14 from '@/assets/img/icons/scenes/SC14.svg'
import SC15 from '@/assets/img/icons/scenes/SC15.svg'
//shutter
import S1 from '@/assets/img/icons/shutter/S1.svg'
import S2 from '@/assets/img/icons/shutter/S2.svg'
import S3 from '@/assets/img/icons/shutter/S3.svg'
import S4 from '@/assets/img/icons/shutter/S4.svg'
import S5 from '@/assets/img/icons/shutter/S5.svg'
import S6 from '@/assets/img/icons/shutter/S6.svg'
import S7 from '@/assets/img/icons/shutter/S7.svg'

const props = defineProps({
      index: Number,
      location: Number,
      top: String,
      left: String,
});

const store = useStore();

const windowSize = ref(window.innerWidth)
const dialogVisible = ref(false)
const selectedIcon = ref('');
const selectedIconUrl = ref('');
const text = ref('')
const activeName = ref('markers')

const windowWidth = computed(() => {
    return windowSize.value <= 1200 ? "80%" : "70%"
})

const changedIcon = (iconName, iconUrl) => { 
  selectedIcon.value = iconName;
  selectedIconUrl.value = iconUrl
}
const confirm = () => { 
    if(selectedIcon.value != '' && activeName.value != 'text'){
        store.commit("setIconPack", {icon: selectedIcon.value, url: selectedIconUrl.value, index: props.index, location: props.location})
        dialogVisible.value = false
    }
    else if(text.value != '' && activeName.value == 'text'){
        store.commit("setIconPack", {icon: text.value, url: 'TXT', index: props.index, location: props.location})
        dialogVisible.value = false 
    }
    else {
        showErrorMessage();
    }
}
const showErrorMessage = () => {
  ElMessage.error('Oops, no icon selected')
}
const handleClick = (tab, event) => {
  console.log(tab, event)
}

onMounted(() => {
    window.addEventListener('resize', () => {windowSize.value = window.innerWidth} )
  })
onUnmounted(() => {
    window.removeEventListener('resize', () => {windowSize.value = window.innerWidth})
})
const appliances = [
        {name: "AP1",url:AP1},
        {name: "AP2",url:AP2},
        {name: "AP3",url:AP3},
        {name: "AP4",url:AP4},
        {name: "AP5",url:AP5},
        {name: "AP6",url:AP6},
        {name: "AP7",url:AP7},
        {name: "AP8",url:AP8},
        {name: "AP9",url:AP9},
    ];
const hvac = [
        {name: "HV1",url:HV1},
        {name: "HV2",url:HV2},
        {name: "HV3",url:HV3},
        {name: "HV4",url:HV4},
        {name: "HV5",url:HV5},
        {name: "HV6",url:HV6},
        {name: "HV7",url:HV7},
        {name: "HV8",url:HV8},
        {name: "HV9",url:HV9},
        {name: "HV10",url:HV10},
        {name: "HV11",url:HV11},
        {name: "HV12",url:HV12},
        {name: "HV13",url:HV13},
        {name: "HV14",url:HV14},
        {name: "HV15",url:HV15},
        {name: "HV16",url:HV16},
        {name: "HV17",url:HV17},
        {name: "HV18",url:HV18},

    ];
const light = [
        {name: "L1",url:L1},
        {name: "L2",url:L2},
        {name: "L3",url:L3},
        {name: "L4",url:L4},
        {name: "L5",url:L5},
        {name: "L6",url:L6},
        {name: "L7",url:L7},
        {name: "L8",url:L8},
        {name: "L9",url:L9},
        {name: "L10",url:L10},
        {name: "L11",url:L11},
        {name: "L12",url:L12},
        {name: "L13",url:L13},
        {name: "L14",url:L14},
        {name: "L15",url:L15},
        {name: "L16",url:L16},
        {name: "L17",url:L17},
        {name: "L18",url:L18},
        {name: "L19",url:L19},
        {name: "L20",url:L20},
    ];
const markers = [
        {name: "M1",url:M1},
        {name: "M2",url:M2},
        {name: "M3",url:M3},
        {name: "M4",url:M4},
        {name: "M5",url:M5},
        {name: "M6",url:M6},
        {name: "M7",url:M7},
    ];
const multimedia = [
        {name: "MM1",url:MM1},
        {name: "MM2",url:MM2},
        {name: "MM3",url:MM3},
        {name: "MM4",url:MM4},
        {name: "MM5",url:MM5},
        {name: "MM6",url:MM6},
        {name: "MM7",url:MM7},
        {name: "MM8",url:MM8},
        {name: "MM9",url:MM9},
        {name: "MM10",url:MM10},
        {name: "MM11",url:MM11},
    ];
const scenes = [
        {name: "SC1",url:SC1},
        {name: "SC2",url:SC2},
        {name: "SC3",url:SC3},
        {name: "SC4",url:SC4},
        {name: "SC5",url:SC5},
        {name: "SC6",url:SC6},
        {name: "SC7",url:SC7},
        {name: "SC8",url:SC8},
        {name: "SC9",url:SC9},
        {name: "SC10",url:SC10},
        {name: "SC11",url:SC11},
        {name: "SC12",url:SC12},
        {name: "SC13",url:SC13},
        {name: "SC14",url:SC14},
        {name: "SC15",url:SC15},
    ];
const shutter = [
        {name: "S1",url:S1},
        {name: "S2",url:S2},
        {name: "S3",url:S3},
        {name: "S4",url:S4},
        {name: "S5",url:S5},
        {name: "S6",url:S6},
        {name: "S7",url:S7},
    ];
</script>

<style scoped>
@import "@/styles/icon-picker.css";
.loc-button {
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 3vw;

}
.primary {
    border-radius: 29px;
    background-color: rgba(0,191,215, 1);
    color: #fff;
}
.icon-button {
    position: relative;
    border: none;
    height: 7vw;
    width: 7vw;
    margin-bottom: 5px;
  }
 .icon-label {
    display: block;
 }
 .icon{
  width: 4vw;
  height: 4vw;
}
 .frame {
  display: inline;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 5px;
  }
.el-input {
    box-shadow: none;
    border-bottom: 5px solid black;
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
    text-align: center;
}
.el-button {
    font-family: 'SF-Compact-Rounded Medium';
}
/* extra large screens */
@media only screen and (min-width: 1941px) {
    .loc-button {
        padding: 25px 20px 25px 20px;
        font-size: 30px;
    }
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
    .loc-button {
        padding: 10px;
        font-size: 9px;
        height: 15px;
    }
}
/* mobile */
@media only screen and (max-width: 768px) {
    .loc-button {
        padding: 10px;
        font-size: 9px;
        height: 15px;
    }
    .icon-button {
        height: 15vw;
        width: 15vw;
    }
    .icon{
        width: 10vw;
        height: 10vw;
    }
    .icon-label {
        font-size: 3vw;
    }
}
@media only screen and (max-width: 450px) {
    .loc-button {
        padding: 10px;
        font-size: 9px;
        height: 15px;
    }
    .icon-button {
        height: 20vw;
        width: 20vw;
    }
    .icon{
        width: 12vw;
        height: 12vw;
    }
    .icon-label {
        font-size: 3vw;
    }
}
</style>