<template>
    <el-row>
      <el-col class="fold" :span="23" :offset="1">
           <span>{{ $t("Brushed Finishes") }}</span>
          <el-divider/>
      </el-col>
    </el-row>
    <el-row>
        <el-col :span="6" v-for="(color,index) in brushedFinishes" :key="index">
            <el-button plain @click="changedKnobColor(color)">
                <el-image class="color" :src=color.url fit="contain"/>
                <el-image class="frame" v-if="color.name == store.getters.getKnobColor" :src=Frame fit="contain" />
            </el-button>
            <span class="icon-label">{{ color.name }}</span>
        </el-col>
    </el-row>
  </template>
  
  <script setup>
    import { ref, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { KNOB, MATERIAL_STEP, CUSTOMIZATION_STEP } from "@/store/constants"
    // @ is an alias to /src
    import Frame from "@/assets/img/frame-material.png"
    //brushed-finishes
    import Brass from "@/assets/img/brushed-finishes/brass.png";
    import StainlessSteel from "@/assets/img/brushed-finishes/stainless-steel.png";
  
    const store = useStore();

    const brushedFinishes = [
        {name: "Brass",url:Brass, code: "CR-ECT-86-KN-BRA", desc: "Eclipse RTC Controller - Knob - Brass"},
        {name: "Stainless Steel",url:StainlessSteel, code: "CR-ECT-86-KN-SST", desc: "Eclipse RTC Controller - Knob - Stainless Steel"}
    ];
  
    const changedKnobColor = (color) => { 
      store.commit("setKnobColor", color.name);
      store.commit("addProductCode",{index: KNOB, code: color.code, desc: color.desc})
    }

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/thermostat/customization',
        previousRoute: '/thermostat/material',
        nextStep: CUSTOMIZATION_STEP,
        previousStep: MATERIAL_STEP
      });
      if(store.getters.getKnobColor == ''){
        store.commit("setKnobColor", "Brass");
        store.commit("addProductCode",{index: KNOB, code: "CR-ECT-86-KN-BRA", desc: "Eclipse RTC Controller - Knob - Brass"})
      }
    })
  </script>
  
  <style scoped>
.el-button {
  position: relative;
  border: none;
  height: 5vw;
  width: 5vw;
  margin-bottom: 5px;
}
.color {
  width: 3vw;
}
.frame {
  display: inline;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 5px;
}
.left {
  text-align: left;
}
.icon-label {
  display: block;
  font-size: 0.9vw;
}
.fold {
  text-align: left;
  font-size: 1.8vw;
  margin-top: 1.6vw;
}
.el-divider{
margin-top: 10px;
}
/* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
}
/* mobile */
@media only screen and (max-width: 768px) {
  .el-button {
    height: 15vw;
    width: 15vw;
  }
  .color {
    width: 9vw;
  }
  .icon-label {
    display: block;
    font-size: 2.5vw;
  }
  .fold {
    font-size: 4vw;
    margin-top: 7vw;
  }
}
  </style>
  