import Vue from "vue";

const navigation = {
    state: {
        activeStep : 0,
        navigation : {
          nextRoute: '',
          previousRoute: '',
          nextStep: 0,
          previousStep: 0
        },
    },
    getters: {
        getActiveStep: state => {return state.activeStep},
        getNavigation: state => {return state.navigation},
    },
    mutations: {
        setActiveStep: (state, stepNumber) => {
            state.activeStep = stepNumber;
          },
          nextStep: state => {
            state.activeStep = state.navigation.nextStep;
          },
          previousStep: state => {
            state.activeStep = state.navigation.previousStep;
          },
          setNavigation: (state, navigation) => {
            state.navigation = navigation;
          },
    },
    actions: {
    },

}

export default navigation;