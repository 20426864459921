<template>
      <el-row>
        <el-col class="fold-text" :span="23" :offset="1">
            <span>{{ $t("Room Terminal Options") }}</span>
            <el-divider/>
        </el-col>
    </el-row>
    <el-row>
        <el-col class="left" :span="23" :offset="1" v-for="(foldOption,index) in foldOptions" :key="index">
          <el-button plain @click="changedFoldOption(foldOption)">
              <el-image class="option" :src=foldOption.url fit="contain" />
              <el-image class="frame" v-if="foldOption.name == store.getters.getFold" :src=Frame fit="contain" />
          </el-button>
          <span class="icon-label">{{ foldOption.name }}</span>
      </el-col>
    </el-row>
</template>
  
  <script setup>
    import { ref, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { FOLD, PRODUCT_STEP, MATERIAL_STEP } from "@/store/constants"
    // @ is an alias to /src
    import KNXTP4 from "@/assets/img/fold-icons/knx-tp-4.png";
    import Frame from "@/assets/img/icon-select-frame.png"

    const store = useStore();

    const foldOptions = [
      {name: "4’’ KNX-TP", url:KNXTP4, code: "CR-ECP-04-KNX", desc: "Eclipse Room Controller 4’’"}
    ];

    const changedFoldOption = (foldOption) => { 
      store.commit("setFold", foldOption.name);
      store.commit("addProductCode",{index: FOLD, code: foldOption.code, desc: foldOption.desc})
    }

    onMounted(() => {
      store.commit("setNavigation", {
        nextRoute: '/roomTerminal/material',
        previousRoute: '/product',
        nextStep: MATERIAL_STEP,
        previousStep: PRODUCT_STEP
      });
      store.commit("setFold", foldOptions[0].name);
      store.commit("addProductCode",{index: FOLD, code: foldOptions[0].code, desc: foldOptions[0].desc})
    })
  </script>

  <style scoped>
  .el-button {
    position: relative;
    border: none;
    height: 7vw;
    width: 7vw;
    margin-bottom: 5px;
  }
  .icon-label {
  margin-left: 1vw;
  font-size: 1vw;
}
.option{
  width: 4vw;
  height: 4vw;
}
 .frame {
  display: inline;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 5px;
  }
  .left {
    text-align: left;
  }
  .fold-text {
  text-align: left;
  font-size: 1.8vw;
  margin-top: 1.6vw;
}
.el-divider{
  margin-top: 10px;
}
/* extra large screens */
@media only screen and (min-width: 1941px) {
  .el-image {
    max-width: 650px;
  }
}
/* large screen */
@media only screen and (max-width: 1940px) {
  .el-image {
    max-width: 400px;
  }
}
/* tablet */
@media only screen and (max-width: 1200px) {
  .el-image {
    max-width: 250px;
  }
}
/* mobile */
@media only screen and (max-width: 768px) {
  .el-button {
    height: 20vw;
    width: 20vw;
  }
  .option{
    width: 12vw;
    height: 12vw;
  }
  .fold-text {
    font-size: 4vw;
    margin-top: 7vw;
  }
  .icon-label {
    margin-left: 3vw;
    font-size: 3vw;
  }
}
  </style>
  