<template>
    <el-row justify="center">
        <el-col :span="24">
            <Preview :fold="store.getters.getFold" 
                     :color="store.getters.getColor" 
                     :iconPack="store.getters.getIconPack" 
                     :iconColorCode="store.getters.getIconColorCode"
                     :isTableView=false>
            </Preview>
        </el-col>
        <el-col class="product-desc" :span="9">
            <span>{{ store.getters.getProductCodes[ELECTRONIC_PART].code }}</span>
        </el-col>
        <el-col class="product-desc" :span="15">
            <span>{{ store.getters.getProductCodes[ELECTRONIC_PART].desc }}</span>
        </el-col>
        <el-col class="product-desc" :span="9" >
            <span>{{ store.getters.getProductCodes[FOLD].code }}</span><span>-</span><span>{{ store.getters.getProductCodes[COLOR].code }}</span>
        </el-col>
        <el-col class="product-desc" :span="15">
            <span>{{ store.getters.getProductCodes[FOLD].desc }}</span><span>-</span><span>{{ store.getters.getProductCodes[COLOR].desc }}</span>
        </el-col>

        <el-col :span="24" v-if="store.getters.getIconPack[0] != undefined || store.getters.getIconPack[1] != undefined">
            <el-row justify="center" v-if="store.getters.getIconPack[0].icon != 'M7' || store.getters.getIconPack[1].icon != 'M7'">
                <el-col class="product-desc" :span="9">
                    <span>CR-ENG-86-1F-</span>
                    <span v-if="store.getters.getIconPack[0] != undefined">{{ store.getters.getIconPack[0].location + 1 }}</span>
                    <span v-if="store.getters.getIconPack[1] != undefined">{{ store.getters.getIconPack[1].location + 1 }}</span>
                    <span>-</span>
                    <span v-if="store.getters.getIconPack[0] != undefined">{{ store.getters.getIconPack[0].url == 'TXT' ? store.getters.getIconPack[0].url : store.getters.getIconPack[0].icon }}</span>
                    <span v-if="store.getters.getIconPack[1] != undefined">{{ store.getters.getIconPack[1].url == 'TXT' ? store.getters.getIconPack[1].url : store.getters.getIconPack[1].icon }}</span>
                </el-col>
                <el-col class="product-desc" :span="15">
                    <span>Custom Engraving for 1 Fold</span>
                </el-col>
            </el-row>
        </el-col>

        <el-col :span="24" v-if="store.getters.getIconPack[2] != undefined || store.getters.getIconPack[3] != undefined">
            <el-row justify="center" v-if="store.getters.getIconPack[2].icon != 'M7' || store.getters.getIconPack[3].icon != 'M7'">
                <el-col class="product-desc" :span="9">
                    <span>CR-ENG-86-1F-</span>
                    <span v-if="store.getters.getIconPack[2] != undefined">{{ store.getters.getIconPack[2].location +1 }}</span>
                    <span v-if="store.getters.getIconPack[3] != undefined">{{ store.getters.getIconPack[3].location +1 }}</span>
                    <span>-</span>
                    <span v-if="store.getters.getIconPack[2] != undefined">{{ store.getters.getIconPack[2].url == 'TXT' ? store.getters.getIconPack[2].url : store.getters.getIconPack[2].icon }}</span>
                    <span v-if="store.getters.getIconPack[3] != undefined">{{ store.getters.getIconPack[3].url == 'TXT' ? store.getters.getIconPack[3].url : store.getters.getIconPack[3].icon }}</span>
                </el-col>
                <el-col class="product-desc" :span="15">
                    <span>Custom Engraving for 1 Fold</span>
                </el-col>
            </el-row>
        </el-col>

        <el-col :span="24" v-if="store.getters.getIconPack[4] != undefined || store.getters.getIconPack[5] != undefined">
            <el-row justify="center" v-if="store.getters.getIconPack[4].icon != 'M7' || store.getters.getIconPack[5].icon != 'M7'">
                <el-col class="product-desc" :span="9">
                    <span>CR-ENG-86-1F-</span>
                    <span v-if="store.getters.getIconPack[4] != undefined">{{ store.getters.getIconPack[4].location +1 }}</span>
                    <span v-if="store.getters.getIconPack[5] != undefined">{{ store.getters.getIconPack[5].location +1 }}</span>
                    <span>-</span>
                    <span v-if="store.getters.getIconPack[4] != undefined">{{ store.getters.getIconPack[4].url == 'TXT' ? store.getters.getIconPack[4].url : store.getters.getIconPack[4].icon }}</span>
                    <span v-if="store.getters.getIconPack[5] != undefined">{{ store.getters.getIconPack[5].url == 'TXT' ? store.getters.getIconPack[5].url : store.getters.getIconPack[5].icon }}</span>
                </el-col>
                <el-col class="product-desc" :span="15">
                    <span>Custom Engraving for 1 Fold</span>
                </el-col>
            </el-row>
        </el-col>

        <el-col :span="24" v-if="store.getters.getIconPack[6] != undefined || store.getters.getIconPack[7] != undefined">
            <el-row justify="center" v-if="store.getters.getIconPack[6].icon != 'M7' || store.getters.getIconPack[7].icon != 'M7'">
                <el-col class="product-desc" :span="9">
                    <span>CR-ENG-86-1F-</span>
                    <span v-if="store.getters.getIconPack[6] != undefined">{{ store.getters.getIconPack[6].location +1 }}</span>
                    <span v-if="store.getters.getIconPack[7] != undefined">{{ store.getters.getIconPack[7].location +1 }}</span>
                    <span>-</span>
                    <span v-if="store.getters.getIconPack[6] != undefined">{{ store.getters.getIconPack[6].url == 'TXT' ? store.getters.getIconPack[6].url : store.getters.getIconPack[6].icon }}</span>
                    <span v-if="store.getters.getIconPack[7] != undefined">{{ store.getters.getIconPack[7].url == 'TXT' ? store.getters.getIconPack[7].url : store.getters.getIconPack[7].icon }}</span>
                </el-col>
                <el-col class="product-desc" :span="15">
                    <span>Custom Engraving for 1 Fold</span>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>
  
  <script setup>
    import { ref, reactive, computed } from 'vue'
    import { useStore } from 'vuex'
    // @ is an alias to /src
    import Preview from './Preview.vue'
    import { FOLD } from "@/store/constants"
    import { COLOR } from "@/store/constants"
    import { ELECTRONIC_PART } from "@/store/constants"

    // CR-ENG-86-1F Custom Engraving for 1 Fold

    const store = useStore();

  </script>

  <style scoped>
 .product-desc {
    margin-top: 10px;
    font-size: 0.75vw;
 }
    /* extra large screens */
@media only screen and (min-width: 1941px) {
}
/* large screen */
@media only screen and (max-width: 1940px) {
}
/* tablet */
@media only screen and (max-width: 1200px) {
}
/* mobile */
@media only screen and (max-width: 768px) {
 .product-desc {
    margin-top: 3.5vw;
    font-size: 2.5vw;
 }
}
  </style>
  