<template>
  <div id="pdfContent" class="common-layout">
    <el-container>
      <el-main>
        <el-row justify="center" class="margin-header">
          <el-col class="align-left" :span="11">
            <el-image class="align-left" style="width: 130px;" :src=CoreLogo fit="contain" />
          </el-col>
          <el-col class="align-right" :span="11">
            <el-image v-if="$i18n.locale == 'gb'" class="align-right" style="width: 160px;" :src=Configuration fit="contain" />
            <el-image v-if="$i18n.locale == 'tr'" class="align-right" style="width: 160px;" :src=ConfigurationTR fit="contain" />
            <el-image v-if="$i18n.locale == 'it'" class="align-right" style="width: 160px;" :src=ConfigurationIT fit="contain" />
          </el-col>
        </el-row>
        <el-row justify="start" class="margin-header label">
          <el-col class="align-left" :span="1">
            <!-- just to give some space on the left -->
          </el-col>
          <el-col class="align-left" :span="17">
            <el-row style="margin-top: 3px;">
              <el-col class="align-left" :span="4">{{ $t("Company Name") }}</el-col>
              <el-col class="align-right" :span="1">:</el-col>
              <el-col class="align-right" :span="1"></el-col> <!-- to give some space -->
              <el-col class="align-left" :span="18"> {{ companyName }}</el-col>
            </el-row>
            <el-row style="margin-top: 3px;">
              <el-col class="align-left" :span="4">{{ $t("Contact Name") }}</el-col>
              <el-col class="align-right" :span="1">:</el-col>
              <el-col class="align-right" :span="1"></el-col> <!-- to give some space -->
              <el-col class="align-left" :span="18"> {{ contactPerson }}</el-col>
            </el-row>
            <el-row style="margin-top: 3px;">
              <el-col class="align-left" :span="4">{{ $t("Project") }}</el-col>
              <el-col class="align-right" :span="1">:</el-col>
              <el-col class="align-right" :span="1"></el-col> <!-- to give some space -->
              <el-col class="align-left" :span="18">{{ projectName }}</el-col>
            </el-row>
          </el-col>
          <el-col class="align-right" :span="1">
            <!-- just to give some space -->
          </el-col>
          <el-col class="align-right" :span="4">
            <el-row style="margin-top: 3px;">
              <el-col class="align-left" :span="8">{{ $t("Date") }}</el-col>
              <el-col class="align-right" :span="1">:</el-col>
              <el-col class="align-right" :span="1"></el-col> <!-- to give some space -->
              <el-col class="align-left" :span="14">{{ date }}</el-col>
            </el-row>
            <el-row style="margin-top: 3px;">
              <el-col class="align-left" :span="8">RevNo</el-col>
              <el-col class="align-right" :span="1">:</el-col>
              <el-col class="align-right" :span="1"></el-col> <!-- to give some space -->
              <el-col class="align-left" :span="14">{{ revNo }}</el-col>
            </el-row>
          </el-col>
          <el-col class="align-right" :span="1">
            <!-- just to give some space on the right -->
          </el-col>
        </el-row>
        <el-row justify="center" class="margin-header">
          <el-col :span="22">
            <el-table :data="productCart.slice(0, 3)" :header-cell-style="{ color: 'black' }" :cell-style="{padding: '0',height: '205px'}">
              <el-table-column align="center" type="index" :label="`${$t('No:')}`" width="50" />
                <el-table-column align="center" prop="name" :label="`${$t('Photo')}`" width="175">
                   <template #default="props">
                    <div class="preview">
                          <ClimateGateway v-if="props.row.name == 'climateGateway'" 
                            :fold=props.row.fold
                            :isTableView=false
                            :isPdfView= true
                          />
                          <TouchPanel v-if="props.row.name == 'touchPanel'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=false
                            :isPdfView= true
                          />
                          <PushButtonSwitch v-if="props.row.name == 'pushButtonSwitch'" 
                            :fold=props.row.fold 
                            :color=props.row.color 
                            :iconPack=props.row.iconPack 
                            iconSize="small" 
                            :iconColorCode=props.row.iconColorCode
                            :isTableView=false
                            :isPdfView= true
                          />
                          <RoomTerminal v-if="props.row.name == 'roomTerminal'" 
                            :fold=props.row.fold 
                            :color=props.row.color 
                            :isTableView=false
                            :isPdfView= true
                          />
                          <SurfacePanel v-if="props.row.name == 'surfacePanel'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=false
                            :isPdfView= true
                          />
                          <Thermostat v-if="props.row.name == 'thermostat'" 
                            :fold="props.row.fold" 
                            :color="props.row.color" 
                            :knobColor="props.row.knobColor" 
                            :iconPack="props.row.iconPack"
                            iconSize="small"
                            :iconColorCode=props.row.iconColorCode
                            :isTableView=false
                            :isPdfView= true
                          />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :align="center" prop="codes" :label="`${$t('Details')}`" min-width="135">
                  <template #default="product">
                        <div v-if="product.row.name == 'climateGateway'">
                          <span class="details">{{product.row.codes.electronicPart.code}}</span>
                          <span class="details">{{product.row.codes.fold.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'touchPanel'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'roomTerminal'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'surfacePanel'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'pushButtonSwitch'">
                           <span class="details">{{product.row.codes.electronicPart.code}}</span>
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].location +1 }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].url == 'TXT' ? product.row.iconPack[0].url : product.row.iconPack[0].icon }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].url == 'TXT' ? product.row.iconPack[1].url : product.row.iconPack[1].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[2] != undefined || product.row.iconPack[3] != undefined">
                            <div v-if="product.row.iconPack[2].icon != 'M7' || product.row.iconPack[3].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[2] != undefined">{{ product.row.iconPack[2].location +1 }}</span>
                              <span v-if="product.row.iconPack[3] != undefined">{{ product.row.iconPack[3].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[2] != undefined">{{ product.row.iconPack[2].url == 'TXT' ? product.row.iconPack[2].url : product.row.iconPack[2].icon }}</span>
                              <span v-if="product.row.iconPack[3] != undefined">{{ product.row.iconPack[3].url == 'TXT' ? product.row.iconPack[3].url : product.row.iconPack[3].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[4] != undefined || product.row.iconPack[5] != undefined">
                            <div v-if="product.row.iconPack[4].icon != 'M7' || product.row.iconPack[5].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[4] != undefined">{{ product.row.iconPack[4].location +1 }}</span>
                              <span v-if="product.row.iconPack[5] != undefined">{{ product.row.iconPack[5].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[4] != undefined">{{ product.row.iconPack[4].url == 'TXT' ? product.row.iconPack[4].url : product.row.iconPack[4].icon }}</span>
                              <span v-if="product.row.iconPack[5] != undefined">{{ product.row.iconPack[5].url == 'TXT' ? product.row.iconPack[5].url : product.row.iconPack[5].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[6] != undefined || product.row.iconPack[7] != undefined">
                            <div v-if="product.row.iconPack[6].icon != 'M7' || product.row.iconPack[7].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[6] != undefined">{{ product.row.iconPack[6].location +1 }}</span>
                              <span v-if="product.row.iconPack[7] != undefined">{{ product.row.iconPack[7].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[6] != undefined">{{ product.row.iconPack[6].url == 'TXT' ? product.row.iconPack[6].url : product.row.iconPack[6].icon }}</span>
                              <span v-if="product.row.iconPack[7] != undefined">{{ product.row.iconPack[7].url == 'TXT' ? product.row.iconPack[7].url : product.row.iconPack[7].icon }}</span>
                            </div>
                           </div>
                           
                        </div>

                        <div v-if="product.row.name == 'thermostat'">
                           <span class="details">{{product.row.codes.electronicPart.code}}</span>
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                           <span class="details">{{product.row.codes.knob.code}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].location +1 }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].url == 'TXT' ? product.row.iconPack[0].url : product.row.iconPack[0].icon }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].url == 'TXT' ? product.row.iconPack[1].url : product.row.iconPack[1].icon }}</span>
                            </div>
                           </div>
                        </div>

                      </template>
                  </el-table-column>
                  <el-table-column :align="center" prop="codes" min-width="200">
                    <template #default="product">
                        <div v-if="product.row.name == 'climateGateway'">
                          <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                          <span class="details">{{product.row.codes.fold.desc}}</span>
                        </div>
                        <div v-if="product.row.name == 'touchPanel'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'roomTerminal'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'surfacePanel'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'pushButtonSwitch'">
                          <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                          <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                          <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[2] != undefined || product.row.iconPack[3] != undefined">
                            <div v-if="product.row.iconPack[2].icon != 'M7' || product.row.iconPack[3].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[4] != undefined || product.row.iconPack[5] != undefined">
                            <div v-if="product.row.iconPack[4].icon != 'M7' || product.row.iconPack[5].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[6] != undefined || product.row.iconPack[7] != undefined">
                            <div v-if="product.row.iconPack[6].icon != 'M7' || product.row.iconPack[7].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                        </div>

                        <div v-if="product.row.name == 'thermostat'">
                           <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                           <span class="details">{{product.row.codes.knob.desc}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                        </div>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" prop="quantity" :label="`${$t('Qty')}`" min-width="40"/>
                  <el-table-column class="note" align="center" prop="note" :label="`${$t('Note')}`" min-width="120"/>
            </el-table>
          </el-col>
        </el-row>
        <el-row :style="marginTop" justify="center">
          <el-col class="footer" :span="22">
            <el-image :src=Footer fit="contain" />
          </el-col>
        </el-row>

        <div v-for="(n, i) in loopCount" :key="n">
          <el-row justify="center" class="margin-header2">
          <el-col class="align-left" :span="11">
            <el-image class="align-left" style="width: 130px;" :src=CoreLogo fit="contain" />
          </el-col>
          <el-col class="align-right" :span="11">
            <el-image class="align-right" style="width: 160px;" :src=Configuration fit="contain" />
          </el-col>
        </el-row>
          <el-row v-if="productCart.length > 3"  justify="center" style="margin-top: 25px;">
          <el-col :span="22">
            <el-table height="860" :data="productCart.slice((4*i)+3, (4*i)+7)" :header-cell-style="{ color: 'black' }" :cell-style="{padding: '0',height: '205px'}">
              <el-table-column align="center" :label="`${$t('No:')}`" width="50">
                <template #default="scope">
                    <span>{{ (4* i) + 4 + scope.$index}}</span>
                </template>
              </el-table-column>
                <el-table-column align="center" prop="name" :label="`${$t('Photo')}`" width="175">
                   <template #default="props">
                    <div class="preview">
                          <ClimateGateway v-if="props.row.name == 'climateGateway'" 
                            :fold=props.row.fold
                            :isTableView=false
                            :isPdfView= true
                          />
                          <TouchPanel v-if="props.row.name == 'touchPanel'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=false
                            :isPdfView= true
                          />
                          <PushButtonSwitch v-if="props.row.name == 'pushButtonSwitch'" 
                            :fold=props.row.fold 
                            :color=props.row.color 
                            :iconPack=props.row.iconPack 
                            iconSize="small" 
                            :iconColorCode=props.row.iconColorCode
                            :isTableView=false
                            :isPdfView= true
                          />
                          <RoomTerminal v-if="props.row.name == 'roomTerminal'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=false
                            :isPdfView= true
                          />
                          <SurfacePanel v-if="props.row.name == 'surfacePanel'" 
                            :fold=props.row.fold 
                            :color=props.row.color
                            :isTableView=false
                            :isPdfView= true
                          />
                          <Thermostat v-if="props.row.name == 'thermostat'" 
                            :fold="props.row.fold" 
                            :color="props.row.color" 
                            :knobColor="props.row.knobColor" 
                            :iconPack="props.row.iconPack"
                            iconSize="small"
                            :iconColorCode=props.row.iconColorCode
                            :isTableView=false
                            :isPdfView= true
                          />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :align="center" prop="codes" :label="`${$t('Details')}`" min-width="135">
                  <template #default="product">
                        <div v-if="product.row.name == 'climateGateway'">
                          <span class="details">{{product.row.codes.electronicPart.code}}</span>
                          <span class="details">{{product.row.codes.fold.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'touchPanel'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'roomTerminal'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'surfacePanel'">
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                        </div>

                        <div v-if="product.row.name == 'pushButtonSwitch'">
                           <span class="details">{{product.row.codes.electronicPart.code}}</span>
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].location +1 }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].url == 'TXT' ? product.row.iconPack[0].url : product.row.iconPack[0].icon }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].url == 'TXT' ? product.row.iconPack[1].url : product.row.iconPack[1].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[2] != undefined || product.row.iconPack[3] != undefined">
                            <div v-if="product.row.iconPack[2].icon != 'M7' || product.row.iconPack[3].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[2] != undefined">{{ product.row.iconPack[2].location +1 }}</span>
                              <span v-if="product.row.iconPack[3] != undefined">{{ product.row.iconPack[3].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[2] != undefined">{{ product.row.iconPack[2].url == 'TXT' ? product.row.iconPack[2].url : product.row.iconPack[2].icon }}</span>
                              <span v-if="product.row.iconPack[3] != undefined">{{ product.row.iconPack[3].url == 'TXT' ? product.row.iconPack[3].url : product.row.iconPack[3].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[4] != undefined || product.row.iconPack[5] != undefined">
                            <div v-if="product.row.iconPack[4].icon != 'M7' || product.row.iconPack[5].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[4] != undefined">{{ product.row.iconPack[4].location +1 }}</span>
                              <span v-if="product.row.iconPack[5] != undefined">{{ product.row.iconPack[5].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[4] != undefined">{{ product.row.iconPack[4].url == 'TXT' ? product.row.iconPack[4].url : product.row.iconPack[4].icon }}</span>
                              <span v-if="product.row.iconPack[5] != undefined">{{ product.row.iconPack[5].url == 'TXT' ? product.row.iconPack[5].url : product.row.iconPack[5].icon }}</span>
                            </div>
                           </div>

                           <div class="details" v-if="product.row.iconPack[6] != undefined || product.row.iconPack[7] != undefined">
                            <div v-if="product.row.iconPack[6].icon != 'M7' || product.row.iconPack[7].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[6] != undefined">{{ product.row.iconPack[6].location +1 }}</span>
                              <span v-if="product.row.iconPack[7] != undefined">{{ product.row.iconPack[7].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[6] != undefined">{{ product.row.iconPack[6].url == 'TXT' ? product.row.iconPack[6].url : product.row.iconPack[6].icon }}</span>
                              <span v-if="product.row.iconPack[7] != undefined">{{ product.row.iconPack[7].url == 'TXT' ? product.row.iconPack[7].url : product.row.iconPack[7].icon }}</span>
                            </div>
                           </div>
                           
                        </div>

                        <div v-if="product.row.name == 'thermostat'">
                           <span class="details">{{product.row.codes.electronicPart.code}}</span>
                           <span class="details">{{product.row.codes.fold.code}}-{{product.row.codes.color.code}}</span>
                           <span class="details">{{product.row.codes.knob.code}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>CR-ENG-86-1F-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].location +1 }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].location +1 }}</span>
                              <span>-</span>
                              <span v-if="product.row.iconPack[0] != undefined">{{ product.row.iconPack[0].url == 'TXT' ? product.row.iconPack[0].url : product.row.iconPack[0].icon }}</span>
                              <span v-if="product.row.iconPack[1] != undefined">{{ product.row.iconPack[1].url == 'TXT' ? product.row.iconPack[1].url : product.row.iconPack[1].icon }}</span>
                            </div>
                           </div>
                        </div>

                      </template>
                  </el-table-column>
                  <el-table-column :align="center" prop="codes" min-width="200">
                    <template #default="product">
                        <div v-if="product.row.name == 'climateGateway'">
                          <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                          <span class="details">{{product.row.codes.fold.desc}}</span>
                        </div>
                        <div v-if="product.row.name == 'touchPanel'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'roomTerminal'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'surfacePanel'">
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                        </div>

                        <div v-if="product.row.name == 'pushButtonSwitch'">
                          <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                          <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                          <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[2] != undefined || product.row.iconPack[3] != undefined">
                            <div v-if="product.row.iconPack[2].icon != 'M7' || product.row.iconPack[3].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[4] != undefined || product.row.iconPack[5] != undefined">
                            <div v-if="product.row.iconPack[4].icon != 'M7' || product.row.iconPack[5].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                          <div class="details" v-if="product.row.iconPack[6] != undefined || product.row.iconPack[7] != undefined">
                            <div v-if="product.row.iconPack[6].icon != 'M7' || product.row.iconPack[7].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                        </div>

                        <div v-if="product.row.name == 'thermostat'">
                           <span class="details">{{product.row.codes.electronicPart.desc}}</span>
                           <span class="details">{{product.row.codes.fold.desc}}-{{product.row.codes.color.desc}}</span>
                           <span class="details">{{product.row.codes.knob.desc}}</span>
                           <div class="details" v-if="product.row.iconPack[0] != undefined || product.row.iconPack[1] != undefined">
                            <div v-if="product.row.iconPack[0].icon != 'M7' || product.row.iconPack[1].icon != 'M7'">
                              <span>Custom Engraving for 1 Fold</span>
                            </div>
                          </div>
                        </div>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" prop="quantity" :label="`${$t('Qty')}`" min-width="40"/>
                  <el-table-column class="note" align="center" prop="note" :label="`${$t('Note')}`" min-width="120"/>
            </el-table>
          </el-col>
        </el-row>
        <div v-if="productCart.length > 3">
          <el-row v-if="productCart.slice((4*i)+3, (4*i)+7).length % 4 == 0" justify="center" style="margin-top: 0px;">
            <el-col class="footer" :span="22">
              <el-image :src=Footer fit="contain" />
            </el-col>
          </el-row>
          <el-row v-if="productCart.slice((4*i)+3, (4*i)+7).length % 4 == 1" justify="center" style="margin-top: 0px;">
            <el-col class="footer" :span="22">
              <el-image :src=Footer fit="contain" />
            </el-col>
          </el-row>
          <el-row v-if="productCart.slice((4*i)+3, (4*i)+7).length % 4 == 2" justify="center" style="margin-top: 0px;">
            <el-col class="footer" :span="22">
              <el-image :src=Footer fit="contain" />
            </el-col>
          </el-row>
          <el-row v-if="productCart.slice((4*i)+3, (4*i)+7).length % 4 == 3" justify="center" style="margin-top: 0px;">
            <el-col class="footer" :span="22">
              <el-image :src=Footer fit="contain" />
            </el-col>
          </el-row>
        </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
  import { ref, defineProps, computed, onMounted} from 'vue'
  import { ElMessageBox } from 'element-plus'
  import {useRoute, useRouter} from 'vue-router'
  import { useStore } from 'vuex'
  // @ is an alias to /src
  // import CoreLogo from "../assets/img/core-logo-black.png";
  import CoreLogo from "../assets/img/pdf-core-logo.png";
  import Configuration from "../assets/img/pdf-config.png";
  import ConfigurationTR from "../assets/img/pdf-config-tr.png";
  import ConfigurationIT from "../assets/img/pdf-config-it.png";
  import Footer from "../assets/img/pdf-footer.png";
  import ClimateGateway from "@/components/climateGateway/Preview.vue"
  import TouchPanel from "@/components/eclipseTouchPanel/Preview.vue"
  import PushButtonSwitch from "@/components/pushButtonSwitch/Preview.vue"
  import RoomTerminal from "@/components/roomTerminal/Preview.vue"
  import SurfacePanel from "@/components/surfaceTouchPanel/Preview.vue"
  import Thermostat from "@/components/thermostat/Preview.vue"
  import AluminumBlack7 from "@/assets/img/touch-panel/7inch/aluminum-black.png"

  import Icon from "@/components/Icon.vue"

  const props = defineProps({
      projectName: String,
      companyName: String,
      contactPerson: String,
      date: Number,
      revNo: String
  });
  const store = useStore();
  const router = useRouter();

  const num = ref(1)
  const productCart = ref(store.getters.getCart);
  const loopCount = computed(() => {
        if(productCart.value.length > 3){
          let temp = Math.floor((productCart.value.length - 3) / 4);
        return (productCart.value.length - 3) % 4 > 0 ? temp + 1 : temp
        }
        else {
          return 0
        }

    })

  const marginTop = computed(() => {
        if(productCart.value.length == 1) return 'margin-top: 540px';
        if(productCart.value.length == 2) return 'margin-top: 335px';
        else return 'margin-top: 130px'
    })
  
</script>

<!-- <style scoped lang="css" src="@/styles/pdf-content.css"> -->
<style scoped>
@import "@/styles/pdf-content.css";
.margin-header {
  margin-top: 30px;
}
.margin-header2 {
  margin-top: 60px;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
pre {
  margin: 0;
  font-family: "SF-Compact-Rounded", sans-serif;
}
.preview {
  text-align: center;
}
.details {
  display: block;
}
.el-table {
    color: #000;
    font-family: "SF-Compact-Rounded", sans-serif;
    font-size: 9px;
}
.label {
    color: #000;
    font-family: "SF-Compact-Rounded", sans-serif;
    font-size: 9px;
}
.footer{
  padding: 20px 0 0 0;
}

.el-main {
  margin-bottom: 0px;
}
</style>
