<template>
  <el-row class="bg-dark" justify="center">
          <el-col :span="4">
              <el-button class="center" type="primary" link @click="previousStep">{{ $t("Back") }}</el-button>
          </el-col>
          <el-col :span="4" :offset="5">
              <el-button v-if="downloadButton" class="center" type="primary" link @click="nextStep">{{ $t("Next") }}</el-button>
              <ProjectInformation v-if="!downloadButton"/>
          </el-col>
          <el-col :span="1" :push="5">
            <span>{{ VERSION }}</span>
          </el-col>
        </el-row>
</template>

<script setup>
import { useStore } from 'vuex'
import {useRouter} from 'vue-router'
import { ref, reactive, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { VERSION, CUSTOMIZATION_STEP, CONFIGURATION_STEP, MATERIAL_STEP, EXPIRE_TIME, PRODUCT_STEP, MODEL_STEP } from "@/store/constants"
import { getExpireDate, setExpireDate, setCart, getCart, removeCart } from "@/api/auth"

import ProjectInformation from "@/components/ProjectInformation.vue"

const store = useStore();
const router = useRouter();

const noCustomizationStepProducts = [
  "surfacePanel","touchPanel", "roomTerminal"
]

const onlyModelStepProducts = [
  "climateGateway"
]

const nextStep = () => { 
  const expireDate = getExpireDate();

    if(onlyModelStepProducts.includes(store.getters.getProductName) && store.getters.getActiveStep == MODEL_STEP){
      store.commit("addProduct");
    }
    else if(noCustomizationStepProducts.includes(store.getters.getProductName) && store.getters.getActiveStep == MATERIAL_STEP){
      store.commit("addProduct");
    }
    else if(!noCustomizationStepProducts.includes(store.getters.getProductName) && store.getters.getActiveStep == CUSTOMIZATION_STEP) {
      store.commit("addProduct");
    }

    if(expireDate < Date.now()){
      removeCart();
      store.commit("clearCart");
      store.commit("setActiveStep", PRODUCT_STEP)
      router.push('/product')
    }
    else {
      setCart(JSON.parse(JSON.stringify(store.getters.getCart)))
      router.push(store.getters.getNavigation.nextRoute);
      store.commit("nextStep");
    }
    setExpireDate(Date.now() + EXPIRE_TIME)
  }


  const previousStep = () => { 
    const expireDate = getExpireDate();
    if(store.getters.getActiveStep == CONFIGURATION_STEP) {
      store.commit("popProduct");
    }
    if(expireDate < Date.now()){
      removeCart();
      store.commit("clearCart");
      store.commit("setActiveStep", PRODUCT_STEP)
      router.push('/product')
    }
    else {
      router.push(store.getters.getNavigation.previousRoute);
      store.commit("previousStep")
    }
    setExpireDate(Date.now() + EXPIRE_TIME)
  }

  const downloadButton = computed(() => {
  return store.getters.getActiveStep >= CONFIGURATION_STEP ? false : true
  
})
const test = () => { 
    router.push('/test');
  }
</script>

<style scoped>
.bg-dark {
  background-color: black;
  width: 100%;
  height: 7vh;
}
.el-button {
  height: 7vh;
  color: #fff;
  font-size: 3.5vh;
  font-family: 'SF-Compact-Rounded Medium';
}

.el-button.is-link:hover {
    color: #ddd;
}
.center {
  margin: auto;
  width: 60%;
  padding: 10px;
}
</style>
