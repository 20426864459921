const KEY_CART = "core-cart"
const KEY_EXPIRE_DATE = "core-expire-date"

export function getExpireDate(){
    return localStorage.getItem(KEY_EXPIRE_DATE)
}

export function setExpireDate(expireDate) {
    localStorage.setItem(KEY_EXPIRE_DATE, expireDate)
}

export function getCart(){
    return localStorage.getItem(KEY_CART)
}

export function setCart(productCart){
    return localStorage.setItem(KEY_CART, JSON.stringify(productCart))
}

export function removeCart(){
    localStorage.removeItem(KEY_CART)
}
