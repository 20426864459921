<template>
    <div class="image-box" :class="{ preTable: isActiveTableView, prePdf: isActivePdfView }">
        <el-image :src=EclipseThermostat fit="contain" style="z-index: -99;"/>
        <Transition>
          <el-image v-if="color != ''" :key="colors[color]" :src=colors[color] fit="contain" class="fold-image" style="position: absolute;"/>
        </Transition>
        <el-image v-if="color != ''" class="fold-image" style="z-index: -2;" :src=ObsidianBlack fit="contain"/>

        <Transition>
          <el-image v-if="knobColor != ''" :key="knobs[knobColor]" class="fold-image" :src=knobs[knobColor] fit="contain" style="position: absolute; z-index: 2;"/>
        </Transition>
        <el-image v-if="knobColor != ''" class="fold-image" :src=BrassKnob fit="contain"/>
        <!-- icons -->
        <!-- Icon 1 -->
        <el-icon :class=iconImage v-if="iconPack[0] != undefined" :style="{color: iconColorCode}" style="left: 13%; top: 87%;">
          <Icon :name="iconPack[0].icon"/>
        </el-icon>
        <div>
          <span v-if="iconPack[0] != undefined && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>
        </div>
        
        <!-- <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 87%;">
          <Icon :name="iconPack[0].icon"/>
        </el-icon>
        <el-icon :class=iconImage v-if="iconPack[0] != undefined && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 13%; top: 85%;">
          <Icon :name="iconPack[0].icon"/>
        </el-icon>
        <div v-if="iconSize != 'small'">
          <span v-if="iconPack[0] != undefined && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 87%;">{{iconPack[0].icon}}</span>
        </div>
        <div v-if="iconSize == 'small'">
          <span v-if="iconPack[0] != undefined && iconPack[0].url == 'TXT' && !isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 13%; top: 85%;">{{iconPack[0].icon}}</span>
          <span v-if="iconPack[0] != undefined && iconPack[0].url == 'TXT' && isRTL(iconPack[0].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 13%; top: 85%;">{{iconPack[0].icon}}</span>
        </div> -->

        <!-- Icon 2 -->
        <el-icon :class=iconImage v-if="iconPack[1] != undefined" :style="{color: iconColorCode}" style="left: 87%; top: 87%;">
          <Icon :name="iconPack[1].icon"/>
        </el-icon>
        <div>
          <span v-if="iconPack[1] != undefined && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon]  style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
        </div>

        <!-- <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconSize != 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 87%;">
          <Icon :name="iconPack[1].icon"/>
        </el-icon>
        <el-icon :class=iconImage v-if="iconPack[1] != undefined && iconSize == 'small'" :style="{color: iconColorCode}" style="left: 87%; top: 85%;">
          <Icon :name="iconPack[1].icon"/>
        </el-icon>
        <div v-if="iconSize != 'small'">
          <span v-if="iconPack[1] != undefined && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon]  style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
         <span v-if="iconPack[1] != undefined && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 87%;">{{iconPack[1].icon}}</span>
        </div>
        <div v-if="iconSize == 'small'">
          <span v-if="iconPack[1] != undefined && iconPack[1].url == 'TXT' && !isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIcon] style="left: 87%; top: 85%;">{{iconPack[1].icon}}</span>
          <span v-if="iconPack[1] != undefined && iconPack[1].url == 'TXT' && isRTL(iconPack[1].icon)" :style="{color: iconColorCode}" :class=[textIconArabic] style="left: 87%; top: 85%;">{{iconPack[1].icon}}</span>          
        </div> -->

    </div>

    <!-- for animation caching -->
    <div v-if="!isTableView">
      <div v-show="false" v-for="(color,index) in colors" :key="index">
        <el-image :src=color />
      </div>
      <div v-show="false" v-for="(knob,index) in knobs" :key="index">
        <el-image :src=knob />
      </div>
    </div>

</template>
  
  <script setup>
    import { ref, computed, defineProps } from 'vue'
    import { useStore } from 'vuex'
    // @ is an alias to /src
    import EclipseThermostat from "@/assets/img/thermostat/thermostat.png"
    //folds
    import AluminumBlack from "@/assets/img/thermostat/folds/aluminum-black.png"
    import AluminumGrey from "@/assets/img/thermostat/folds/aluminum-grey.png"
    import Brass from "@/assets/img/thermostat/folds/brass.png"
    import CocoaGrey from "@/assets/img/thermostat/folds/cocoa-grey.png"
    import GlacierWhite from "@/assets/img/thermostat/folds/glacier-white.png"
    import ObsidianBlack from "@/assets/img/thermostat/folds/obsidian-black.png"
    import SandBeige from "@/assets/img/thermostat/folds/sand-beige.png"
    import StainlessSteel from "@/assets/img/thermostat/folds/stainless-steel.png"
    import TitaniumGrey from "@/assets/img/thermostat/folds/titanium-grey.png"
    import Verdant from "@/assets/img/thermostat/folds/verdant.png"
    //knobs
    import BrassKnob from "@/assets/img/thermostat/knobs/brass.png"
    import StainlessSteelKnob from "@/assets/img/thermostat/knobs/stainless-steel.png"
    import Icon from "@/components/Icon.vue"

    const props = defineProps({
      fold: String,
      color: String,
      knobColor: String,
      iconPack: Array,
      iconSize: String,
      iconColorCode: String,
      isTableView: Boolean,
      isPdfView: Boolean
    });
    const store = useStore();

    const lightColors = [
      "Aluminum Grey","Stainless Steel", "Glacier White", "Sand Beige"
    ]

    const iconImage = computed(() => {
        return props.iconSize == 'small' ? 'icon-image-s' : 'icon-image-l'
    })
    const textIcon = computed(() => {
        return props.iconSize == 'small' ? 'text-s' : 'text-l'
    })
    const textIconArabic = computed(() => {
        return props.iconSize == 'small' ? 'text-s-arabic' : 'text-l-arabic'
    })
    const isActiveTableView = computed(() => {
        return props.isTableView
    })
    const isActivePdfView = computed(() => {
        return props.isPdfView
    })

    const colors = {
        "Aluminum Black": AluminumBlack,
        "Aluminum Grey": AluminumGrey,
        "Brass": Brass,
        "Cocoa Grey": CocoaGrey,
        "Glacier White": GlacierWhite,
        "Obsidian Black": ObsidianBlack,
        "Sand Beige": SandBeige,
        "Stainless Steel": StainlessSteel,
        "Titanium Grey": TitaniumGrey,
        "Verdant": Verdant,
    };

    const knobs = {
        "Brass": BrassKnob,
        "Stainless Steel": StainlessSteelKnob,
    };

    const isRTL =(s) =>{           
        var ltrChars    = 'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF'+'\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF',
            rtlChars    = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC',
            rtlDirCheck = new RegExp('^[^'+ltrChars+']*['+rtlChars+']');

        return rtlDirCheck.test(s);
    };
  </script>

<style scoped>
.image-box{
   position: relative;
   margin: auto;
 }
 .preTable{
    height: 131px;
  }
  .prePdf{
    height: 151px;
  }
.fold-image {
   display: inline;
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   margin: auto;
}
.icon-image-l{
   position: absolute;
   font-size: 40px;
   transform: translate(-50%, -50%);
}
.icon-image-s{
   position: absolute;
   font-size: 12.5px;
   transform: translate(-50%, -50%);
}
.text-l{
   position: absolute;
   font-size: 20px;
   font-family: "CORELASERV2", sans-serif;
   color: #fff;
   transform: translate(-50%, -50%);
   white-space: nowrap;
}
.text-s{
   position: absolute;
   font-size: 5px;
   font-family: "CORELASERV2", sans-serif;
   line-height: 0;
   color: #fff;
   transform: translate(-50%, -50%);
   white-space: nowrap;
}
.text-l-arabic{
   position: absolute;
   font-size: 15px;
   font-family: "CorelaserArabic", sans-serif;
   color: #fff;
   transform: translate(-50%, -50%);
   white-space: nowrap;
}
.text-s-arabic{
   position: absolute;
   font-size: 4px;
   font-family: "CorelaserArabic", sans-serif;
   line-height: 0;
   color: #fff;
   transform: translate(-50%, -50%);
   white-space: nowrap;
}
.text-white{
 color: #fff;
}
.text-black{
 color: #000;
}
/* extra large screens */
@media only screen and (min-width: 1941px) {
 .image-box,
 .el-image {
   max-width: 650px;
   max-height: 650px;
 }
 .preTable{
    height: 301px;
  }
 .icon-image-l{
   font-size: 65px;
}
.icon-image-s{
   font-size: 30px;
}
.text-l{
   font-size: 32.5px;
}
.text-s{
    font-size: 10px;
 }
 .text-s-arabic{
    font-size: 10px;
 }
}
/* large screen */
@media only screen and (max-width: 1940px) {
 .image-box,
 .el-image {
   max-width: 400px;
   max-height: 400px;
 }
}
/* tablet */
@media only screen and (max-width: 1200px) {
 .image-box,
 .el-image {
   max-width: 250px;
   max-height: 250px;
 }
 .icon-image-l{
   font-size: 25px;
}
.text-l{
   font-size: 12.5px;
}
}
/* mobile */
@media only screen and (max-width: 768px) {
 .image-box,
 .el-image {
   max-width: 250px;
   max-height: 250px;
 }
 .icon-image-l{
   font-size: 25px;
}
.text-l{
   font-size: 12.5px;
}
}
/* animation */
.v-enter-active,
.v-leave-active {
  position: relative;
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  position: absolute;
  opacity: 0;
}
 </style>
  