import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/store'

import Main from '../views/Main.vue'
import Customization from '../views/Customization.vue'
import Product from '../views/Product.vue'
import Materials from '../components/Materials.vue'
import Configuration from '../views/Configuration.vue'

import ClimateGatewayFoldOptions from '../components/climateGateway/FoldOptions.vue'
import ClimateGatewayDetails from '../components/climateGateway/Details.vue'
import ClimateGatewayMaterialOptions from '../components/climateGateway/MaterialOptions.vue'
import ClimateGatewayCustomization from '../components/climateGateway/IconSelection.vue'

import EclipseTouchPanelFoldOptions from '../components/eclipseTouchPanel/FoldOptions.vue'
import EclipseTouchPanelDetails from '../components/eclipseTouchPanel/Details.vue'
import EclipseTouchPanelMaterialOptions from '../components/eclipseTouchPanel/MaterialOptions.vue'
import EclipseTouchPanelCustomization from '../components/eclipseTouchPanel/IconSelection.vue'

import RoomTerminalFoldOptions from '../components/roomTerminal/FoldOptions.vue'
import RoomTerminalDetails from '../components/roomTerminal/Details.vue'
import RoomTerminalMaterialOptions from '../components/roomTerminal/MaterialOptions.vue'
import RoomTerminalCustomization from '../components/roomTerminal/IconSelection.vue'

import SurfacePanelFoldOptions from '../components/surfaceTouchPanel/FoldOptions.vue'
import SurfacePanelDetails from '../components/surfaceTouchPanel/Details.vue'
import SurfacePanelMaterialOptions from '../components/surfaceTouchPanel/MaterialOptions.vue'
import SurfacePanelCustomization from '../components/surfaceTouchPanel/IconSelection.vue'

import PushButtonSwitchFoldOptions from '../components/pushButtonSwitch/FoldOptions.vue'
import PushButtonSwitchDetails from '../components/pushButtonSwitch/Details.vue'
import PushButtonSwitchMaterialOptions from '../components/pushButtonSwitch/MaterialOptions.vue'
import PushButtonSwitchCustomization from '../components/pushButtonSwitch/IconSelection.vue'

import ThermostatFoldOptions from '../components/thermostat/FoldOptions.vue'
import ThermostatDetails from '../components/thermostat/Details.vue'
import ThermostatMaterialOptions from '../components/thermostat/MaterialOptions.vue'
import ThermostatMaterialOptionsKnob from '../components/thermostat/MaterialOptionsKnob.vue'
import ThermostatCustomization from '../components/thermostat/IconSelection.vue'

import PdfContent from '../components/PdfContent.vue'


const routes = [
  {
    path: '/',
    redirect: "/product",
    name: 'main',
    leaf: true,
    component: Main,
    children: [
      {
        path: "/product", 
        name: "Product",
        component: Product,
        // component: () => import('../views/Product.vue')
      },
      {
        path: "/test", 
        name: "test",
        component: PdfContent,
        // component: () => import('../views/Product.vue')
      },
      //climate gateway routes
      {
        path: "/climateGateway/model", 
        name: "ClimateGateway",
        component: Customization,
        children: [
          {
            path:'',
            name: "ClimateGateway",
            components: {
              options: ClimateGatewayFoldOptions,
              details: ClimateGatewayDetails
            }
          }
        ]
      },
      {
        path: "/climateGateway/material", 
        name: "ClimateGatewayMaterial",
        component: Customization,
        children: [
          {
            path:'',
            name: "ClimateGatewayMaterial",
            components: {
              options: ClimateGatewayMaterialOptions,
              details: ClimateGatewayDetails
            }
          }
        ]
      },
      {
        path: "/climateGateway/customization", 
        name: "ClimateGatewayCustomization",
        component: Customization,
        children: [
          {
            path:'',
             name: "ClimateGatewayCustomization",
            components: {
              options: ClimateGatewayCustomization,
              details: ClimateGatewayDetails
            }
          }
        ]
      },
      //touch panel routes
      {
        path: "/touchPanel/model", 
        name: "TouchPanel",
        component: Customization,
        children: [
          {
            path:'',
            name: "TouchPanel",
            components: {
              options: EclipseTouchPanelFoldOptions,
              details: EclipseTouchPanelDetails
            }
          }
        ]
      },
      {
        path: "/touchPanel/material", 
        name: "TouchPanelMaterial",
        component: Customization,
        children: [
          {
            path:'',
            name: "TouchPanelMaterial",
            components: {
              options: EclipseTouchPanelMaterialOptions,
              details: EclipseTouchPanelDetails
            }
          }
        ]
      },
      {
        path: "/touchPanel/customization", 
        name: "TouchPanelCustomization",
        component: Customization,
        children: [
          {
            path:'',
            name: "TouchPanelCustomization",
            components: {
              options: EclipseTouchPanelCustomization,
              details: EclipseTouchPanelDetails
            }
          }
        ]
      },
      //push button switch routes
      {
        path: "/pushButtonSwitch/model", 
        name: "PushButtonSwitch",
        component: Customization,
        children: [
          {
            path:'',
            name: "PushButtonSwitch",
            components: {
              options: PushButtonSwitchFoldOptions,
              details: PushButtonSwitchDetails
            }
          }
        ]
      },
      {
        path: "/pushButtonSwitch/material", 
        name: "PushButtonSwitchMaterial",
        component: Customization,
        children: [
          {
            path:'',
            name: "PushButtonSwitchMaterial",
            components: {
              options: PushButtonSwitchMaterialOptions,
              details: PushButtonSwitchDetails
            }
          }
        ]
      },
      {
        path: "/pushButtonSwitch/customization", 
        name: "PushButtonSwitchCustomization",
        component: Customization,
        children: [
          {
            path:'',
            name: "PushButtonSwitchCustomization",
            components: {
              options: PushButtonSwitchCustomization,
              details: PushButtonSwitchDetails
            }
          }
        ]
      },
      //room terminal routes
      {
        path: "/roomTerminal/model", 
        name: "RoomTerminal",
        component: Customization,
        children: [
          {
            path:'',
            name: "RoomTerminal",
            components: {
              options: RoomTerminalFoldOptions,
              details: RoomTerminalDetails
            }
          }
        ]
      },
      {
        path: "/roomTerminal/material", 
        name: "RoomTerminalMaterial",
        component: Customization,
        children: [
          {
            path:'',
            name: "RoomTerminalMaterial",
            components: {
              options: RoomTerminalMaterialOptions,
              details: RoomTerminalDetails
            }
          }
        ]
      },
      {
        path: "/roomTerminal/customization", 
        name: "RoomTerminalCustomization",
        component: Customization,
        children: [
          {
            path:'',
            name: "RoomTerminalCustomization",
            components: {
              options: RoomTerminalCustomization,
              details: RoomTerminalDetails
            }
          }
        ]
      },
      //surface touch panel routes
      {
        path: "/surfacePanel/model", 
        name: "SurfacePanel",
        component: Customization,
        children: [
          {
            path:'',
            name: "SurfacePanel",
            components: {
              options: SurfacePanelFoldOptions,
              details: SurfacePanelDetails
            }
          }
        ]
      },
      {
        path: "/surfacePanel/material", 
        name: "SurfacePanelMaterial",
        component: Customization,
        children: [
          {
            path:'',
            name: "SurfacePanelMaterial",
            components: {
              options: SurfacePanelMaterialOptions,
              details: SurfacePanelDetails
            }
          }
        ]
      },
      {
        path: "/surfacePanel/customization", 
        name: "SurfacePanelCustomization",
        component: Customization,
        children: [
          {
            path:'',
             name: "SurfacePanelCustomization",
            components: {
              options: SurfacePanelCustomization,
              details: SurfacePanelDetails
            }
          }
        ]
      },
      //thermostat routes
      {
        path: "/thermostat/model", 
        name: "Thermostat",
        component: Customization,
        children: [
          {
            path:'',
            name: "Thermostat",
            components: {
              options: ThermostatFoldOptions,
              details: ThermostatDetails
            }
          }
        ]
      },
      {
        path: "/thermostat/material", 
        name: "ThermostatMaterial",
        component: Customization,
        children: [
          {
            path:'',
            name: "ThermostatMaterial",
            components: {
              options: ThermostatMaterialOptions,
              details: ThermostatDetails
            }
          }
        ]
      },
      {
        path: "/thermostat/materialKnob", 
        name: "ThermostatMaterialKnob",
        component: Customization,
        children: [
          {
            path:'',
            name: "ThermostatMaterialKnob",
            components: {
              options: ThermostatMaterialOptionsKnob,
              details: ThermostatDetails
            }
          }
        ]
      },
      {
        path: "/thermostat/customization", 
        name: "ThermostatCustomization",
        component: Customization,
        children: [
          {
            path:'',
            name: "ThermostatCustomization",
            components: {
              options: ThermostatCustomization,
              details: ThermostatDetails
            }
          }
        ]
      },
      {
        path: "/configuration", 
        name: "Configuration",
        component: Configuration,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  console.log("BeforeEach : " + to.path)

  const name = store.getters.getProductName
  const color = store.getters.getColor
  const fold = store.getters.getFold

  if (to.name !== 'Product' && name === '') next({ name: 'Product' })
  else next() 
})

export default router
